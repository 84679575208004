class OrderHelper {

    static isOpen(order){
        if(order.status === 'CONFIRMED' && (order.payment_status === "OPEN" || order.payment_status === "FAILURE")){
            return true;
        }
        return false;
    }
    static isConfirmed(order){
        if(order.status === 'CONFIRMED' && (order.payment_status === "OPEN" || order.payment_status === "FAILURE")){
            return true;
        }
        return false;
    }

    static getItemName(items, id, name){
        let obj = items.filter(item => item.id === id);
        if(obj.length > 0){
            return obj[0].translation.name;
        }
        return name;
    }
}


export default OrderHelper;