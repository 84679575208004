import React, {Component, useEffect, useRef, useState} from 'react';
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {getOrderType, getPickDate} from "../../../../application/cart/reducer";
import DateService from "../../../../application/service/DateService";
import AvailableHelper from "../../../../application/common/AvailableHelper";
import {getAvailabilities} from "../../../../application/menu/reducer";
import {withTranslation} from "react-i18next";
import MenuHelper from "../../../../application/common/MenuHelper";
import {Collapse, Dropdown} from "react-bootstrap";
import {ReactComponent as SearchSVG} from "../../../../images/icons/search.svg";
import {getLangDisplayName} from "../../../../lib/i18n";

const MenuMenus = (props)=>{

    const { t, pick_date, availabilities } = props;
    const [open, setOpen] = useState(false);
    const [searchOpened, setSearchOpened] = useState(false);
    const ref = useRef(null);
    let menus = props.menus;
    menus = menus.sort((a, b) => a.name.localeCompare(b.name));
    let selectedMenuId = props.menuId;
    const renderMenuContent = (menu) => {
        if(!menu) return (
          <>
            Wybierz menu
          </>
        );
        const { t } = props;
        let orderType = props.orderType;
        let orderTypeMenuInfo = null;
        let menuNotAvailableNow = null;
        let openHours = AvailableHelper.getOpenHours(AvailableHelper.getAvailability(availabilities, menu.availability?.id), DateService.emptyOrNow(pick_date));

        let menuErrors = MenuHelper.getMenuErrors(menu, orderType, pick_date, availabilities);
        menuErrors.forEach(menuError => {
            switch (menuError) {
                case "unavailable_type":
                    let menuTypes = [];
                    if(menu.delivery) menuTypes.push(t("models.order.type.DELIVERY"));
                    if(menu.dine_in) menuTypes.push(t("models.order.type.DINE_IN"));
                    if(menu.takeaway) menuTypes.push(t("models.order.type.PICK_UP"));
                    if(menu.room_service) menuTypes.push(t("models.order.type.ROOM_SERVICE"));
                    orderTypeMenuInfo = (
                        // <small className="nav-link-error error">{t("components.menu.unavailable." + orderType)}</small>
                        <small className="nav-link-error error">
                            {/*{t("components.menu.unavailable." + orderType)}:*/}
                            {t("components.menu.available_types")}: {menuTypes.join(", ")}
                        </small>
                    )
                    break;
                case "unavailable_pickup_date":
                    menuNotAvailableNow = (
                        <small className="nav-link-error error">{t("components.menu.unavailable_pickup_date")}</small>
                    )
                    break;
                case "unavailable_date":
                    menuNotAvailableNow = (
                        <small className="nav-link-error error">{pick_date ? t("components.menu.unavailable_pickup_date") : t("components.menu.unavailable_today_pickup_date")}</small>
                    )
                    break;
                case "unavailable_time":
                    menuNotAvailableNow = (
                        <small className="nav-link-error error">{pick_date ? t("components.menu.unavailable_pickup_time") : t("components.menu.unavailable_now")}</small>
                    )
                    break;
            }
        })
        return (
            <div className={"menu-item-content"}>
                <div className={"menu-item-header"}>
                    {menu.translation.name}
                    <ul className="list-inline list-inline-hours">
                        {
                            openHours.map((openHour) => {
                                    return (
                                        <li key={menu.id + "-" + openHour.day_from + "-"  + openHour.day_to + "-"  + openHour.hour_from + "-"  + openHour.hour_to}>
                                            {openHour.hour_from} - {openHour.hour_to}
                                        </li>
                                    )
                                }
                            )
                        }
                    </ul>
                </div>
                <div className={"menu-item-error"}>
                    {menuNotAvailableNow}
                    {orderTypeMenuInfo}
                </div>
            </div>
        )
    }
    useEffect(() => {
        if(ref.current){
            ref.current.focus();
        }
    }, [searchOpened])
    const renderMenuItem = (menu, selectedMenuId) => {
        const { t } = props;
        let orderType = props.orderType;
        let orderTypeMenuInfo = null;
        let menuNotAvailableNow = null;
        let openHours = AvailableHelper.getOpenHours(AvailableHelper.getAvailability(availabilities, menu.availability?.id), DateService.emptyOrNow(pick_date));

        let menuErrors = MenuHelper.getMenuErrors(menu, orderType, pick_date, availabilities);
        menuErrors.forEach(menuError => {
            switch (menuError) {
                case "unavailable_type":
                    let menuTypes = [];
                    if(menu.delivery) menuTypes.push(t("models.order.type.DELIVERY"));
                    if(menu.dine_in) menuTypes.push(t("models.order.type.DINE_IN"));
                    if(menu.takeaway) menuTypes.push(t("models.order.type.PICK_UP"));
                    if(menu.room_service) menuTypes.push(t("models.order.type.ROOM_SERVICE"));
                    orderTypeMenuInfo = (
                        <small className="nav-link-error">
                            {/*{t("components.menu.unavailable." + orderType)}: {menuTypes.join(", ")}*/}
                            {t("components.menu.available_types")}: {menuTypes.join(", ")}
                        </small>
                    )
                    break;
                case "unavailable_pickup_date":
                    menuNotAvailableNow = (
                        <small className="nav-link-error">{t("components.menu.unavailable_pickup_date")}</small>
                    )
                    break;
                case "unavailable_date":
                    menuNotAvailableNow = (
                        <small className="nav-link-error">{pick_date ? t("components.menu.unavailable_pickup_date") : t("components.menu.unavailable_today_pickup_date")}</small>
                    )
                    break;
                case "unavailable_time":
                    menuNotAvailableNow = (
                        <small className="nav-link-error">{pick_date ? t("components.menu.unavailable_pickup_time") : t("components.menu.unavailable_now")}</small>
                    )
                    break;
            }
        })
        return (

            <li className="nav-item" key={menu.id}>
                <Link className={"nav-link" + (selectedMenuId === menu.id ? " active" : "")} to={"/menu/" + menu.id} replace>
                    {menu.translation.name}
                    <ul className="list-inline list-inline-hours">
                        {
                            openHours.map((openHour) => {
                                    return (
                                        <li key={menu.id + "-" + openHour.day_from + "-"  + openHour.day_to + "-"  + openHour.hour_from + "-"  + openHour.hour_to}>
                                            {openHour.hour_from} - {openHour.hour_to}
                                        </li>
                                    )
                                }
                            )
                        }
                    </ul>
                    {menuNotAvailableNow}
                    {orderTypeMenuInfo}
                </Link>
            </li>
        )
    }
    useEffect(() => {
        if(open){
            setOpen(false);
        }
    }, [selectedMenuId])

    const search = (value) => {
        props.search(value);
    }
    const closeSearch = () =>{
        search(undefined)
        setSearchOpened(false);
    }
    const openSearch = () =>{
        setSearchOpened(true);
    }

    let selectedMenu = menus.filter(menu => menu.id === selectedMenuId)[0];
    return(
        <div className="container-menu-picker sticky-fixed  sticky-fixed-menu">
           <div className="container">
               <div className="d-flex">
                   {!searchOpened && (
                       <>
                           <div className="flex-grow-1 container-menu-picker-menus">

                               {!searchOpened && (
                                   <>
                                       <ul className="nav">
                                           {/*<li className="nav-item">*/}
                                           {/*    <span className="nav-link nav-link-header">{t("components.menu.menu")}</span>*/}
                                           {/*</li>*/}
                                           {menus.map((menu) => {
                                                   return renderMenuItem(menu, selectedMenuId)
                                               }
                                           )}
                                       </ul>
                                       <button className={"btn btn-choose-menu w-100"}
                                               onClick={() => setOpen(!open)}
                                               aria-controls="example-collapse-text"
                                               aria-expanded={open}>
                                           {renderMenuContent(selectedMenu)}
                                       </button>
                                   </>
                               )}
                               {searchOpened && (
                                   <input type={"search"} className={"form-control"} onChange={(e) => search(e.target.value)} />
                               )}
                           </div>
                           <div className="ms-2">
                               <button className={"btn btn-lg btn-default"} onClick={() => openSearch()}><SearchSVG/></button>
                           </div>
                       </>
                   )}
                   {searchOpened && (
                       <>
                           <div className="input-group input-group-lg">
                               <input ref={ref} type={"text"} className={"form-control"} placeholder={"Wyszukaj produkt"} onChange={(e) => search(e.target.value)} />
                               <div className="input-group-append">
                                   <button className="btn btn-outline-secondary btn-lg" type="button" onClick={() => closeSearch()}
                                           id="button-addon1">X
                                   </button>
                               </div>
                           </div>
                           {/*<div className={"flex-grow-1"}>*/}
                           {/*    <input type={"search"} className={"form-control"} placeholder={"Wyszukaj produkt"} />*/}
                           {/*</div>*/}
                           {/*<div className="ms-2">*/}
                           {/*    <button className={"btn btn-lg btn-default"} onClick={() => setOpenSearch(!openSearch)}><SearchSVG/></button>*/}
                           {/*</div>*/}
                       </>
                   )}

               </div>

               <Collapse in={open}>
                   <div id="example-collapse-text">
                       <ul className="nav flex-column1">
                           {/*<li className="nav-item">*/}
                           {/*    <span className="nav-link nav-link-header">{t("components.menu.menu")}</span>*/}
                           {/*</li>*/}
                           {menus.map((menu) => {
                                   return renderMenuItem(menu, selectedMenuId)
                               }
                           )}
                       </ul>
                   </div>
               </Collapse>

           </div>
        </div>
    )
}
const mapStateToProps = (state)=>{
    return {
        orderType: getOrderType(state.cart),
        pick_date: getPickDate(state.cart),
        availabilities: getAvailabilities(state.menu)
    }
}

export default withTranslation()(connect(mapStateToProps)(MenuMenus))