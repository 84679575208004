import React, {Component} from 'react';
import {connect} from "react-redux";
import {
    getItemGroups,
    getItems,
    getMenuPending,
    getMenus,
    getModifierGroups,
    isStoreOpen
} from "../../../../application/menu/reducer";
import {Modal} from "react-bootstrap";
import {getCart, getOrderPending} from "../../../../application/cart/reducer";
import LoadingContainer from "../../../common/LoadingContainer";
import ItemHelper from "../../../../application/common/ItemHelper";
import {getLocation} from "../../../../application/location/reducer";
import IframeWidget from "../../../utils/IframeWidget";
import {withTranslation} from "react-i18next";
import MenuShowItemSingle from "../components/MenuShowItemSingle";
import MenuShowItemSingleV3 from "../components/v2/MenuShowItemSingleV3";
import AppConfig from "../../../../application/common/AppConfig";
import Analytics from "../../../../application/common/Analytics";

class MenuAddItem extends Component{
    state = {
        show: true,
        menuPending: true
    };

    showModal = () => {
        this.setState({ show: true });
    };

    hideModal = () => {
        IframeWidget.closeModal();
        const {match} = this.props;
        let menuId = parseInt(match.params.menuId);
        this.setState({ show: false });

        if(this.props.history.location.state !== undefined && this.props.history.location.state.back !== undefined){
            this.props.history.goBack();
        }else{
            this.props.history.replace('/menu/' + menuId, 'stay');
        }
        // this.props.history.push('/menu/' + menuId, 'stay');
    };

    componentDidMount() {
        const {match, items} = this.props;
        let id = parseInt(match.params.id);
        let item = ItemHelper.getItem(items, id);
        if(item){
            Analytics.viewItem(item);
        }
    }


    addItem = (menuId, item)=>{
        this.hideModal();
        // this.setState({ show: false });
        // this.props.history.push('/menu/' + menuId, 'stay');
    }
    render(){
        if(this.props.menuPending){
            return (
                <LoadingContainer/>
            )
        }
        const {items, menus, match, t} = this.props;
        let id = parseInt(match.params.id);
        let menuId = parseInt(match.params.menuId);

        let menusById = menus.filter(menu => menu.id === menuId);
        let menu = null;
        if(menusById.length > 0){
            menu = menusById[0];
        }
        let item = ItemHelper.getItem(items, id);
        if(item === null){
            return null;
        }
        let productImgView = null;
        if(item.image_link){
            productImgView = (
                <div className="product-item-img">
                    <img src={item.image_link.default} />
                </div>
            )
        }
        let itemGroup = ItemHelper.getItemGroup(this.props.itemGroups, item.id);
        // let product = this.props.product;
        if(AppConfig.isDesignV2()){
            return(
                <Modal dialogClassName={"modal-full modal-product modal-product-full"} show={this.state.show} onHide={this.hideModal} onShow={IframeWidget.openModal}>
                    <div className={"container-fluid"}>
                        <div className={"row"}>
                            <div className={"col-md-6"}>
                                {productImgView}
                            </div>
                            <div className={"col-md-6 modal-product-full-right"}>
                                <MenuShowItemSingleV3 itemId={item.id} itemGroup={itemGroup} onUpdate={this.addItem} menu={menu} onHide={this.hideModal} />
                            </div>
                        </div>
                    </div>
                </Modal>
            )
        }
        return(
            <Modal dialogClassName="modal-full modal-product" show={this.state.show} onHide={this.hideModal} onShow={IframeWidget.openModal}>
                {productImgView}
                <Modal.Header>
                    <Modal.Title>
                        {itemGroup ? itemGroup.translation ? itemGroup.translation.name : itemGroup.name : item.translation.name}
                        {/*{item.translation.name}*/}
                    </Modal.Title>
                    <button onClick={this.hideModal} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </Modal.Header>
                <Modal.Body>
                    <MenuShowItemSingle item={item} itemGroup={itemGroup} addItem={this.addItem} menu={menu} onHide={this.hideModal} />
                </Modal.Body>
            </Modal>
        )
    }


}
const mapStateToProps = (state)=>{
    return {
        items: getItems(state.menu),
        itemGroups: getItemGroups(state.menu),
        menus: getMenus(state.menu),
        modifier_groups: getModifierGroups(state.menu),
        pending: getOrderPending(state.cart),
        cart: getCart(state.cart),
        location: getLocation(state.location),
        menuPending: getMenuPending(state.menu),
        isStoreOpen: isStoreOpen(state.menu)
    }
}

export default withTranslation()(connect(mapStateToProps)(MenuAddItem))