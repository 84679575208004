import React from 'react';
import OrderSummaryData from "../../common/order/OrderSummaryData";
import {useTranslation} from "react-i18next";

const SummarySummary = (props)=>{
    const { t } = useTranslation();
    return(
        <div className="card card-shipment">
            <div className="card-body">
                <h3>
                    {t("components.summary.title")}
                </h3>

                <OrderSummaryData order={props.order} />
            </div>

        </div>
    )
}
export default SummarySummary;