import React, {Component, useState} from 'react';
import {connect} from "react-redux";
import {getAddress, getCart, getNumber, getOrderType, getPickDate} from '../../../application/cart/reducer';
import {updateAddress, updateOrderData} from "../../../application/cart/cartService";
import ErrorFormView from "../../common/ErrorFormView";
import ErrorFormHelper from "../../common/ErrorFormHelper";
import {getLocation} from "../../../application/location/reducer";
import {getStore} from "../../../application/store/reducer";
import {emptyOrValue, getAddressName} from "../../../application/common/StringHelper";
import {updateLocation} from "../../../application/location/actions";
import {getAvailabilities, getCustomFields, getLocationStore} from "../../../application/menu/reducer";
import ErrorFormAlertView from "../../common/ErrorFormAlertView";
import {parseDate} from "../../../application/common/DateHelper";
import ChangePickDateModal from "../../common/ChangePickDateModal";
import FormattedTime from "../../common/FormattedTime";
import {withTranslation} from "react-i18next";
import ChangeTableNumberModal from "../../common/ChangeTableNumberModal";
import ChangeRoomServiceNumberModal from "../../common/ChangeRoomServiceNumberModal";
import {CartCustomFields} from "./CartCustomFields";
import MenuHelper from "../../../application/common/MenuHelper";
import LocationSearchWithSave from "../../common/location/LocationSearchWithSave";
import CitySearchComponent from "../../common/location/CitySearchComponent";
import StreetSearchComponent from "../../common/location/StreetSearchComponent";

const CartShipment = (props)=>{
    const [pickDateModal, setPickDateModal] = useState(false);
    const [tableNumberModal, setTableNumberModal] = useState(false);
    const [roomNumberModal, setRoomNumberModal] = useState(false);
    const handleChangeCity = (value, zip) => {
        let address = props.location.address;
        address.city = value;
        address.zip_code = zip;
        props.dispatch(updateLocation(address));
    }
    const handleChangeStreet = (value) => {
        let address = props.location.address;
        address.street = value;
        props.dispatch(updateLocation(address));
    }
    // handleChangeStreet(event) {
    //     let address = props.location.address;
    //     address.street = event.target.value;
    //     props.dispatch(updateLocation(address));
    // }
    const handleChangeNumber = (event) => {
        let address = props.location.address;
        address.build_nr = event.target.value;
        props.dispatch(updateLocation(address));
    }
    const handleChangeFlatNumber = (event) => {
        let address = props.location.address;
        address.flat_nr = event.target.value;
        props.dispatch(updateLocation(address));
    }
    const handleBlurTableName = (event) => {
        let number = event.target.value;
        let data = {
            number: number,
            type: props.type
        }
        props.dispatch(updateOrderData(data, props.cart)).then((newData) => {
        }).catch(error => {
        });
    }
    const handleChange = (event) => {
    }


    const handleChangeType = (event) => {
        let type = event.target.value;
        switch (type) {
            case "DINE_IN":
                setTableNumberModal(true)
                break;
            case "ROOM_SERVICE":
                setRoomNumberModal(true)
                break;
            default:
                let data = {
                    type: type
                }
                if(type === 'DINE_IN'){
                    data.number = props.number;
                }
                props.dispatch(updateOrderData(data, props.cart)).then((newData) => {
                }).catch(error => {
                });
                break;
        }
    }
    const refreshLocation = () => {
        const {location} = props;
        props.dispatch(updateAddress(location.address, props.cart)).then(data => {
        }).catch(error => {
        });
    }

    const showPickDateModal = () => {
        setPickDateModal(true)
    }
    const hidePickDateModal = () => {
        setPickDateModal(false);
    }

    const {type, location, locationStore, number, pick_date, t} = props;
    let isSelectCities = false;
    if(props.store.settings && props.store.settings.cities_radius){
        isSelectCities = true;
    }
    let address = location.address;
    let addressView = null;
    let phoneRequired = false;
    let emailRequired = false;
    if(type === "DELIVERY"){
        phoneRequired = true;
        emailRequired = true;
        let isDeliveryAreaView = null;
        if (!locationStore.in_delivery_area) {
            let errorMEssage = [t("errors.internal.store_address_beyond_delivery")];
            isDeliveryAreaView = (
                <ErrorFormAlertView errors={errorMEssage}/>
            );
        }
        addressView = (
            <React.Fragment>
                {isDeliveryAreaView}
                <div className="form-row row">
                    {isSelectCities ? (
                        <>
                            <div className={`form-group col-lg-4  col-sm-6${address.city ? " focus" : ""}`}>
                                <CitySearchComponent placeholder={t("common.form.address.city") + "*"} onBlur={refreshLocation} value={address.city} zip={address.zip_code} onChange={(value, zip) => handleChangeCity(value, zip)} />
                                <label htmlFor="city">{t("common.form.address.city")}</label>
                            </div>
                            <div className={`form-group col-lg-4  col-sm-6${address.street ? " focus" : ""}`}>
                                <StreetSearchComponent city={address.city} placeholder={t("common.form.address.street") + "*"} onBlur={refreshLocation} value={address.street} onChange={(value) => handleChangeStreet(value)} />
                                <label htmlFor="street">{t("common.form.address.street")}</label>
                            </div>
                        </>
                    ) : (
                        <div className="form-group col-lg-8 col-sm-6">
                            <LocationSearchWithSave/>
                        </div>
                    )}
                    <div className="form-group col-lg-2 col-sm-6">
                        <input type="text" value={address.build_nr} onBlur={refreshLocation} onChange={handleChangeNumber} className={"form-control" + ErrorFormHelper(props.errors, "address.buildNr")} id="build_nr" placeholder={t("common.form.address.number")+"*"} />
                        <label htmlFor="build_nr">{t("common.form.address.number")}</label>
                        <ErrorFormView errors={props.errors} field="address.buildNr" />
                    </div>
                    <div className="form-group col-lg-2  col-sm-6">
                        <input type="text" value={address.flat_nr} onChange={handleChangeFlatNumber} className={"form-control" + ErrorFormHelper(props.errors, "address.flat_nr")} id="flat_nr" placeholder={t("common.form.address.flat_nr")} />
                        <label htmlFor="flat_nr">{t("common.form.address.flat_nr")}</label>
                        <ErrorFormView errors={props.errors} field="address.flat_nr" />
                    </div>
                </div>
            </React.Fragment>
        )
    }else if(type === "PICK_UP"){
        let addressText = getAddressName(props.store.address);
        if(addressText === null){
            addressText = t("errors.internal.no_address");
        }
        addressView = (
            <p>
                {t("models.order.type."+type)}: {addressText}
            </p>
        )
    }else if(type === "DINE_IN"){
        let tableName = number;
        addressView = (
            <React.Fragment>
                <button type="button" className={"btn btn-default btn-block btn-pick-date" + ErrorFormHelper(props.errors, "number")}  onClick={() => setTableNumberModal(true)}>
                    <h5>
                        {tableName ? (
                            <>
                                {tableName}
                                <small>
                                    {t("components.table_number_modal.form.table_number")}
                                </small>
                            </>
                        ) : (
                            <>
                                {t("common.action.choose_table")}
                                <small>
                                    {t("components.table_number_modal.form.table_number")}
                                </small>
                            </>
                        )}
                    </h5>
                </button>
                <ErrorFormView errors={props.errors} field="number" />
            </React.Fragment>
        )
    }else if(type === "ROOM_SERVICE"){
        let roomNumber = number;
        addressView = (
            <React.Fragment>
                <button type="button" className={"btn btn-default btn-block btn-pick-date" + ErrorFormHelper(props.errors, "number")}  onClick={() => setRoomNumberModal(true)}>
                    <h5>
                        {roomNumber ? (
                            <>
                                {roomNumber}
                                <small>
                                    {t("components.room_number_modal.form.room_number")}
                                </small>
                            </>
                        ) : (
                            <>
                                {t("common.action.choose_room")}
                                <small>
                                    {t("components.room_number_modal.form.room_number")}
                                </small>
                            </>
                        )}
                    </h5>
                </button>
                <ErrorFormView errors={props.errors} field="number" />
            </React.Fragment>
        )
    }
    let deliveryOptionView = null;
    let pickupOptionView = null;
    let dineInOptionView = null;
    let pickDateOptionView = null;
    let roomServiceOptionView = null;
    if(locationStore.delivery || type === 'DELIVERY'){
        deliveryOptionView = (
            <label className={"btn btn-default" + (type === "DELIVERY" ? ' active' : '')}>
                <input className="btn-check" type="radio" name="type" value="DELIVERY" autoComplete="off" checked={type === "DELIVERY"} onChange={handleChangeType} /> {t("models.order.type.DELIVERY")}
            </label>
        );
    }
    if(locationStore.takeaway || type === 'PICK_UP'){
        pickupOptionView = (
            <label className={"btn btn-default" + (type === "PICK_UP" ? ' active' : '')}>
                <input className="btn-check" type="radio" name="type" value="PICK_UP" autoComplete="off" checked={type === "PICK_UP"} onChange={handleChangeType} /> {t("models.order.type.PICK_UP")}
            </label>
        );
    }

    if(locationStore.dine_in || type === 'DINE_IN'){
        dineInOptionView = (
            <label className={"btn btn-default" + (type === "DINE_IN" ? ' active' : '')}>
                <input className="btn-check" type="radio" name="type" value="DINE_IN" autoComplete="off" checked={type === "DINE_IN"} onChange={handleChangeType} /> {t("models.order.type.DINE_IN")}
                <ChangeTableNumberModal tableName={number} show={tableNumberModal}  onSave={() => setTableNumberModal(false)} onHide={() => setTableNumberModal(false)}/>
            </label>
        );
    }

    if(locationStore.room_service || type === 'ROOM_SERVICE'){
        roomServiceOptionView = (
            <label className={"btn btn-default" + (type === "ROOM_SERVICE" ? ' active' : '')}>
                <input className="btn-check" type="radio" name="type" value="ROOM_SERVICE" autoComplete="off" checked={type === "ROOM_SERVICE"} onChange={handleChangeType} /> {t("models.order.type.ROOM_SERVICE")}
                <ChangeRoomServiceNumberModal roomNumber={number} show={roomNumberModal}  onSave={() => setRoomNumberModal(false)} onHide={() => setRoomNumberModal(false)}/>
            </label>
        );
    }

    if(locationStore.pick_date && (type === 'DELIVERY' || type === 'PICK_UP' || type === 'ROOM_SERVICE' || type === 'DINE_IN')){

        let datePickedView = null;
        let datePickedTextView = null;
        if(type === "DELIVERY" || type === 'ROOM_SERVICE') {
            datePickedTextView = t("components.cart.shipment.delivery_time");
        }else if(type === "PICK_UP") {
            datePickedTextView = t("components.cart.shipment.pickup_time");
        }else if(type === "DINE_IN") {
            datePickedTextView = t("components.cart.shipment.pickup_time");
        }
        if(pick_date){
            let date = parseDate(pick_date);
            datePickedView = (
                <React.Fragment>
                    <FormattedTime
                        value={date}
                        day="numeric"
                        month="long"/>
                </React.Fragment>
            )
        }else{
            datePickedView = t("components.cart.shipment.delivery_as_soon_as_possible");
        }

        pickDateOptionView = (
            <React.Fragment>
                <button type="button" className="btn btn-default btn-block btn-pick-date" onClick={showPickDateModal}>
                    <h5>
                        {datePickedTextView}
                        <small>
                            {datePickedView}
                        </small>
                    </h5>
                </button>
                <ChangePickDateModal pickDate={props.pick_date} showModal={pickDateModal} onHide={hidePickDateModal} />
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <div className="card card-shipment">
                <div className="card-body">
                    <h3>
                        {t("components.cart.shipment.title")}
                    </h3>
                    <div>
                        <div className="card-shipment-type">
                            <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                {deliveryOptionView}
                                {pickupOptionView}
                                {dineInOptionView}
                                {roomServiceOptionView}
                            </div>
                        </div>
                        {addressView}
                        {MenuHelper.isCustomFieldExist(props.custom_fields, props.type, props.store, "ADDRESS") && (
                            <div className="form-row row">
                                <CartCustomFields availabilities={props.availabilities} section={"ADDRESS"} store={props.store} orderType={props.type} customFields={props.custom_fields} valueCustomFields={props.cart.custom_fields} errors={props.errors} onChange={props.onChangeCustomField} />
                            </div>
                        )}
                        {pickDateOptionView}
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
const mapStateToProps = (state)=>{
    return {
        address: getAddress(state.cart),
        location: getLocation(state.location),
        locationStore: getLocationStore(state.menu),
        type: getOrderType(state.cart),
        number: getNumber(state.cart),
        cart: getCart(state.cart),
        store: getStore(state.store),
        pick_date: getPickDate(state.cart),
        custom_fields: getCustomFields(state.menu),
        availabilities: getAvailabilities(state.menu)
    }
}

export default withTranslation()(connect(mapStateToProps)(CartShipment))