import React from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getAddressName} from "../../application/common/StringHelper";
import {getStore} from "../../application/store/reducer";

function NavbarMultiStore(props){
    let portalUrl = props.backUrl;
    const renderSearchInfo = () => {
        let actionEdit = (
            <>
                {portalUrl ? (
                     <>
                         {" "}<a href={portalUrl} className={"link-primary"}> Zmień lokal</a>
                     </>
                ) : (
                    <>
                        {" "}<span className={"link-primary"}> Zmień lokal</span>
                    </>
                )}
            </>
        )
        let addressName = getAddressName(props.store.address);
        return (
            <>
                {addressName && (
                  <>
                      {addressName}<br />
                  </>
                )}
                {actionEdit}
            </>
        );
    }
    const change = () => {
        if(portalUrl){
            window.location.href = portalUrl;
            return;
        }
        window.ReactNativeWebView.postMessage('BACK');
    }
    if(!props.multiStore && !portalUrl) return null;

    return (
        <>
            {portalUrl ? (
                <div className={"navbar-actions"} onClick={() => change()}>
                    <div className={"content"}>
                        {renderSearchInfo()}
                    </div>
                </div>
            ) : (
                <button className={"navbar-actions"} onClick={() => change()}>
                    <div className={"content"}>
                        {renderSearchInfo()}
                    </div>
                </button>
            )}
        </>
    )
}

const mapStateToProps = (state)=>{
    return {
        store: getStore(state.store),
    }
}
export default withTranslation()(connect(mapStateToProps)(NavbarMultiStore))
