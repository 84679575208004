import React, {useState} from 'react';
import {connect} from "react-redux";
import {getCart, getCartPromotions, getContact, getOrderType} from "../../../application/cart/reducer";
import ErrorFormHelper from "../../common/ErrorFormHelper";
import {withTranslation} from "react-i18next";
import {getLoginProviders, getStore} from "../../../application/store/reducer";
import {getUser, getVouchers} from "../../../application/user/reducer";
import UserService from "../../../application/user/userService";
import {getIntegrations} from "../../../application/menu/reducer";
import ErrorFormAlertView from "../../common/ErrorFormAlertView";

const CartApps = (props)=>{
    const [clientForm, setClientForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [errors, setErrors] = useState([]);
    const [password, setPassword] = useState("");
    const { t, type, store, user, integrations } = props;

    const removeClient = () => {
        console.log("removeClient");
        props.dispatch(UserService.logout()).then(() => {
            console.log("removeClient #2");
        }).catch(error => {
            console.log(error);
            // props.updateErrors(errors);
            // this.updateErrors(error.errors);
            // this.setState({loading: false, errors:error.errors});
        });
    }
    if(!integrations || integrations.length <=0){
        return null;
    }
    let clientsIntegration = integrations.filter(integration => {
        return integration.provider === "GOCLIENT" || integration.provider === "GOCRM";
    });
    // if(integrationGoClient === undefined){
    //     return null;
    // }
    if(clientsIntegration.length <= 0 && (!user || !user.name)){
        return null;
    }
    let provider = clientsIntegration[0].provider;
    const showForm = () => {
        setClientForm(true);
        if(props.email !== undefined){
            setEmail(props.email);
        }
    }

    const addClient = async () => {
        try {
            let response = await props.dispatch(UserService.login(provider, email, password))
            props.onChangeEmail(email);
            setClientForm(false);
        }catch (e) {
            if(e.status === 403 || e.status === 404){
                setErrors([
                    {
                        "code": "wrong_login",
                        "message": "Niepoprawne dane do zalogowania"
                    }
                ])
            }else if(e.status == 422){
                setErrors(e.errors);
            }
            // setErrors(e.errors)
            // console.log(e);
            // throw e;
        }
    }
    return(
        <div className="card card-shipment">
            <div className="card-body">
                <h3>
                    {t("components.apps.loyalty.title")}
                </h3>
                {user && user.name ? (
                    <>
                        <p>
                            <span dangerouslySetInnerHTML={{__html: t("components.apps.loyalty.description_logged", {"name": user.name})}} /> <button className="btn btn-sm" onClick={(e) => removeClient()}>{t("components.apps.loyalty.description_logged_action")}</button>
                        </p>
                    </>
                ): clientsIntegration.length > 0 ? (
                    <>                                                                                                                                                                                        
                        <p>
                            {t("components.apps.loyalty.description_logout")} <button className="btn btn-sm" onClick={(e) => showForm()}>{t("components.apps.loyalty.description_logout_action")}</button>
                        </p>
                        {clientForm && (
                            <div>
                                <ErrorFormAlertView errors={errors} />
                                <div className={"form-group input-group mb-3" + ErrorFormHelper(errors, undefined)}>
                                    <div className={"form-group-container"}>
                                        <input type="text" onChange={(e) => setEmail(e.target.value)} value={email} className={"form-control" + ErrorFormHelper(errors, "username")} placeholder={t("components.apps.loyalty.form.login")} aria-label="Recipient's username" aria-describedby="cart-username" />
                                        <label htmlFor="cart-username">{t("components.apps.loyalty.form.login")}</label>
                                    </div>
                                    <div className={"form-group-container"}>
                                        <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} className={"form-control" + ErrorFormHelper(errors, "password")} placeholder={t("components.apps.loyalty.form.password")} aria-label="Recipient's username" aria-describedby="cart-password" />
                                        <label htmlFor="cart-password">{t("components.apps.loyalty.form.password")}</label>
                                    </div>
                                    <div className="input-group-append">
                                        <button className={"btn btn-primary btn-spinner" + (loading ? ' loading' : '')} type="button" id="cart-cardCode" disabled={loading} onClick={addClient} >
                                            <span className="left spinner-border spinner-border-sm"></span>
                                            {t("common.action.add")}
                                        </button>
                                    </div>
                                    <div className="input-group-append">
                                        <button className={"btn btn-danger btn-spinner" + (loading ? ' loading' : '')} type="button" id="cart-cardCode" disabled={loading} onClick={() => setClientForm(false)} >
                                            <span className="left spinner-border spinner-border-sm"></span>
                                            {t("common.action.cancel")}
                                        </button>
                                    </div>
                                </div>
                                <hr />
                            </div>
                        )}
                    </>
                ) : null}
            </div>

        </div>
    )
}
const mapStateToProps = (state)=>{
    return {
        contact: getContact(state.cart),
        type: getOrderType(state.cart),
        store: getStore(state.store),
        cart: getCart(state.cart),
        user: getUser(state.user),
        vouchers: getVouchers(state.user),
        integrations: getIntegrations(state.menu),
        loginProviders: getLoginProviders(state.store),
        orderPromotions: getCartPromotions(state.cart),
        // errors: getOrderErrors(state.cart)
    }
}

export default withTranslation()(connect(mapStateToProps)(CartApps))