import {LOGIN, LOGOUT, VOUCHERS} from './actions'


const initialState = {
    id: null,
    name: "",
    provider: "",
    points: 0,
    token: "",
    email: "",
    phone: "",
    vouchers: []
}

const userReducer= (state = initialState,action)=>{
    switch(action.type) {
        case LOGIN:
            let user = action.user;
            return {
                ...state,
                name: user.contact.name,
                email: user.contact.email,
                phone: user.contact.phone,
                points: user.contact.points,
                provider: user.contact.provider,
                token: user.token,
            }
        case LOGOUT:
            return{
                ...initialState
            }
        case VOUCHERS:
            let vouchers = action.vouchers;
            return {
                ...state,
                vouchers: vouchers
            }
        default:
            return state;
    }
}

export default userReducer;
export const getUserToken = state => state.token;
export const getUser = state => state;
export const getVouchers = state => state.vouchers;