import React, {Component} from 'react';
import {connect} from "react-redux";
import {
    getAvailabilities,
    getItems,
    getMenuPending,
    getMenus,
    getModifierGroups,
    getPromotions,
    isStoreOpen
} from "../../../../application/menu/reducer";
import {addItem} from "../../../../application/cart/cartService";
import {getCart, getOrderPending, getPickDate} from "../../../../application/cart/reducer";
import MenuModifierGroups from "./MenuShowItem/MenuModifierGroups";
import LoadingContainer from "../../../common/LoadingContainer";
import ItemHelper from "../../../../application/common/ItemHelper";
import {getLocation} from "../../../../application/location/reducer";
import ErrorFormAlertView from "../../../common/ErrorFormAlertView";
import DateService from "../../../../application/service/DateService";
import AvailableHelper from "../../../../application/common/AvailableHelper";
import Utils from "../../../utils/Utils";
import Money from "../../../common/Money";
import {withTranslation} from "react-i18next";
import {getLocale, isPreview} from "../../../../application/store/reducer";
import ModifierGroupHelper from "../../../../application/common/ModifierGroupHelper";
import PromotionHelper from "../../../../application/common/PromotionHelper";
import {ReactComponent as StarIco} from "../../../../images/icons/star.svg";
import ItemViewHelper from "../../../../application/common/ItemViewHelper";

class MenuShowItem extends Component{
    state = {
        // show: true,
        item: {
            item_id: null,
            name: null,
            quantity: 0,
            modifier_groups:[],
            price: 0,
            unit_total_price: 0,
            total_price: 0
        },
        errors:[],
        menuPending: true
    };

    // showModal = () => {
    //     this.setState({ show: true });
    // };

    // hideModal = () => {
    //     let menuId = parseInt(this.props.menuId);
    //     this.setState({ show: false });
    //     this.props.history.push('/menu/' + menuId, 'stay');
    // };
    addQuantity = () => {
        this.setQuantity(this.state.item.quantity+1);
    };
    subQuantity = () => {
        this.setQuantity(this.state.item.quantity-1);
    };
    addItem = ()=>{
        const {cart, location} = this.props;
        this.setState({errors: []});
        let menuId = parseInt(this.props.menuId);
        // let newItem = {
        //     item_id: item.id,
        //     quantity: this.state.quantity
        // };
        let newItem = this.state.item;
        let orderData = {
            address: location.address,
            contact: cart.contact,
            type: cart.type,
            estimated_preparation_at: cart.estimated_preparation_at,
            pickup_at: cart.pickup_at,
            number: cart.number,
            locale: this.props.locale
        }
        this.props.dispatch(addItem(newItem, orderData)).then(response => {
            this.props.addItem(menuId, newItem);
        }).catch(resError => {
            this.setState({errors: resError.errors});
        });
    }
    setQuantityItem = (modifierGroup, item, quantity) => {
        if(modifierGroup.min_permitted === 1 && modifierGroup.max_permitted === 1){
            let stateModifierGroup = this.getOrCreateStateModifierGroup(modifierGroup.id);
            stateModifierGroup.selected_items = [];
        }
        let modifierGroupItem = this.getOrCreateStateModifierGroupItemOption(modifierGroup.id, item);
        modifierGroupItem.quantity = quantity;
        modifierGroupItem.total_price = modifierGroupItem.price * modifierGroupItem.quantity;
        this.refreshStateModifierGroupItemOption(modifierGroup.id);
    }
    subQuantityItem = (modifierGroup, item) => {
        let modifierGroupItem = this.getOrCreateStateModifierGroupItemOption(modifierGroup.id, item);
        modifierGroupItem.quantity = modifierGroupItem.quantity - 1;
        modifierGroupItem.total_price = modifierGroupItem.price * modifierGroupItem.quantity;
        this.refreshStateModifierGroupItemOption(modifierGroup.id);
    }
    addQuantityItem = (modifierGroup, item) => {
        if(modifierGroup.min_permitted === 1 && modifierGroup.max_permitted === 1){
            let stateModifierGroup = this.getOrCreateStateModifierGroup(modifierGroup.id);
            stateModifierGroup.selected_items = [];
        }
        let modifierGroupItem = this.getOrCreateStateModifierGroupItemOption(modifierGroup.id, item);
        modifierGroupItem.quantity = modifierGroupItem.quantity + 1;
        modifierGroupItem.total_price = modifierGroupItem.price * modifierGroupItem.quantity;
        this.refreshStateModifierGroupItemOption(modifierGroup.id);
    }
    refreshStateModifierGroupItemOption(modifierGroupId){
        const {items, modifier_groups} = this.props;
        let stateModifierGroup = this.getOrCreateStateModifierGroup(modifierGroupId);
        let modifierGroup = ItemHelper.getModifierGroup(modifier_groups, modifierGroupId);
        stateModifierGroup.selected_items = stateModifierGroup.selected_items.filter(selectedItem => selectedItem.quantity > 0);

        let item = ItemHelper.getItem(items, this.state.item.item_id);
        MenuShowItem.updateStateSelectedItemsChargeAbove(stateModifierGroup.selected_items, items, item, modifierGroup);

        if(stateModifierGroup.selected_items.length <= 0){
            this.state.item.modifier_groups = this.state.item.modifier_groups.filter(itemModifierGroup => itemModifierGroup.modifier_group_id !== stateModifierGroup.id);
        }
        this.updateState();
    }
    static updateStateSelectedItemsChargeAbove(stateSelectedItems, items, parentItem, modifierGroup){
        let modifierGroupQuantityInfo = ItemHelper.getModifierGroupQuantityInfo(modifierGroup, parentItem);
        stateSelectedItems.forEach(stateSelectedItem => {
            MenuShowItem.updateStateSelectedItemChargeAbove(stateSelectedItem, items, parentItem, modifierGroup);
        });
        if(modifierGroupQuantityInfo !== null && modifierGroupQuantityInfo.charge_above > 0){

            let quantityFree = stateSelectedItems.reduce((prev,next) => prev + next.quantity_free,0);
            let chargeAboveQuantity = modifierGroupQuantityInfo.charge_above - quantityFree;
            if(chargeAboveQuantity){
                stateSelectedItems.filter(stateSelectedItem => stateSelectedItem.quantity > stateSelectedItem.quantity_free).sort((a, b) => b.price - a.price).forEach(stateSelectedItem => {
                    if(chargeAboveQuantity <= 0){
                        return;
                    }
                    let quantityToFreeCharge = stateSelectedItem.quantity - stateSelectedItem.quantity_free;
                    quantityToFreeCharge = quantityToFreeCharge > chargeAboveQuantity ? chargeAboveQuantity : quantityToFreeCharge;
                    stateSelectedItem.quantity_free += quantityToFreeCharge;
                    stateSelectedItem.total_price = stateSelectedItem.total_price - (stateSelectedItem.price * quantityToFreeCharge);
                    chargeAboveQuantity = chargeAboveQuantity - quantityToFreeCharge;
                });
            }
        }

    }
    static updateStateSelectedItemChargeAbove(stateSelectedItem, items, parentItem, modifierGroup){
        // let totalPrice = stateSelectedItem.total_price;
        let totalPrice = stateSelectedItem.price * stateSelectedItem.quantity;
        let selectedItem = ItemHelper.getItem(items, stateSelectedItem.item_id);
        let selectedItemQuantityInfo = ItemHelper.getItemQuantityInfo(selectedItem, parentItem, modifierGroup);
        if(selectedItemQuantityInfo !== null && selectedItemQuantityInfo.charge_above > 0){
            let quantityChargeAbove = stateSelectedItem.quantity - selectedItemQuantityInfo.charge_above;
            quantityChargeAbove = quantityChargeAbove > 0 ? quantityChargeAbove : 0;
            let quantityChargeBelow = stateSelectedItem.quantity - quantityChargeAbove;
            totalPrice = 0;
            if(quantityChargeAbove > 0){
                totalPrice = stateSelectedItem.price * quantityChargeAbove;
            }
            stateSelectedItem.quantity_free = quantityChargeBelow;
        }else{
            stateSelectedItem.quantity_free = 0;
        }
        stateSelectedItem.total_price = totalPrice;
    }
    getOrCreateStateModifierGroup(modifierGroupId){
        let modifierGroup = this.state.item.modifier_groups.filter(modifierGroup => modifierGroup.modifier_group_id === modifierGroupId)[0];
        if(modifierGroup === undefined){
            modifierGroup = {
                modifier_group_id: modifierGroupId,
                selected_items: []
            };
            this.state.item.modifier_groups.push(modifierGroup);
        }
        return modifierGroup;
    }
    getOrCreateStateModifierGroupItemOption(modifierGroupId, item){
        let modifierGroup = this.getOrCreateStateModifierGroup(modifierGroupId);
        let modifierGroupItem = modifierGroup.selected_items.filter(selectedItem => selectedItem.item_id === item.id)[0];
        if(modifierGroupItem === undefined){
            modifierGroupItem = {
                item_id: item.id,
                quantity: 0,
                price: item.price !== undefined ? item.price : 0
            };
            modifierGroup.selected_items.push(modifierGroupItem);
        }
        return modifierGroupItem;
    }
    setQuantity(quantity){
        let newQuantity = quantity;
        if(newQuantity <=0){
            newQuantity = 1;
        }
        this.state.item.quantity = newQuantity;
        this.updateState();
        // let total_price = this.state.item.unit_total_price * newQuantity;
        // this.setState(prevState => ({
        //     ...prevState,
        //     item: {
        //         ...prevState.item,
        //         quantity: newQuantity,
        //         total_price: total_price
        //     }
        // }));
    }
    total_price(){
        return this.state.item.unit_total_price * this.state.quantity;
    }

    componentDidMount() {
        const {items, modifier_groups, pending} = this.props;
        let id = parseInt(this.props.id);
        let stateItem = MenuShowItem.getInitItem(id, items, modifier_groups, this.state);
        if(stateItem === null){
            return;
        }
        this.state.item = stateItem;
        this.updateState();
    }

    static getInitItem(id, items, modiferGroups, state){
        if(state.item.item_id === id){
            return null;
        }
        let item = ItemHelper.getItem(items, id);
        if(item === null){
            return null;
        }
        let itemPrice = item.price !== undefined ? item.price.amount : 0;
        let stateItem = {
            item_id: item.id,
            price: itemPrice,
            name: item.translation.name,
            unit_total_price: itemPrice,
            total_price: itemPrice,
            quantity: 1,
            modifier_groups:[]
        }
        item.modifier_groups.forEach(itemModifierGroup => {
            let modifierGroup = ItemHelper.getModifierGroup(modiferGroups, itemModifierGroup.modifier_group_id);
            if(modifierGroup === null){
                return;
            }
            let stateModifierGroup = {
                selected_items: [],
                modifier_group_id: modifierGroup.id
            }
            modifierGroup.options.forEach(modifierGroupOption => {
                let modifierGroupItem = ItemHelper.getItem(items, modifierGroupOption.entity_id);
                if(modifierGroupItem !== null){

                    let quantityInfo = ItemHelper.getItemQuantityInfo(modifierGroupItem, item, modifierGroup);
                    if(quantityInfo !== null && quantityInfo.default_quantity > 0){

                        let stateModifierGroupItemPrice = ItemHelper.getPriceOvveride(modifierGroupItem, item, modifierGroup);
                        let quantity = quantityInfo.default_quantity !== null ? quantityInfo.default_quantity : 0;
                        let stateModifierGroupItem = {
                            item_id: modifierGroupItem.id,
                            price: stateModifierGroupItemPrice,
                            name: modifierGroupItem.name,
                            unit_total_price: stateModifierGroupItemPrice,
                            total_price: stateModifierGroupItemPrice * quantity,
                            quantity: quantity,
                            quantity_free: 0
                        }
                        stateModifierGroup.selected_items.push(stateModifierGroupItem);
                    }
                }
           })

            MenuShowItem.updateStateSelectedItemsChargeAbove(stateModifierGroup.selected_items, items, item, modifierGroup);
            if(stateModifierGroup.selected_items.length > 0){
                stateItem.modifier_groups.push(stateModifierGroup);
            }
        });
        return stateItem;
    }

    static refreshItemPrice(item, itemPromotions, cart){
        if(item === null){
            return;
        }
        let total_price = 0;
        total_price = item.modifier_groups.map(modifierGroup => {
            let modifierGroupPrice = modifierGroup.selected_items.reduce((prev,next) => prev + next.total_price,0);
            return modifierGroupPrice;
        }).reduce((prev,next) => prev + next,0);
        item.unit_total_price = item.price + total_price;
        item.total_price = item.unit_total_price * item.quantity;
        let discountedPrice = ItemViewHelper.getDiscountPrice(item.item_id, item.quantity, item.unit_total_price, itemPromotions, cart, item.price);
        item.discount_price = discountedPrice;

    }

    getStateModifierGroup(modifierGroupId){
        return this.state.item.modifier_groups.filter(modifierGroup => modifierGroup.modifier_group_id === modifierGroupId)[0];
    }
    getStateModifierGroupSelectedItem(modifierGroup, item_id){
        if(!modifierGroup){
            return null;
        }
        return modifierGroup.selected_items.filter(selectedItem => selectedItem.item_id === item_id)[0];
    }
    updateState(){
        MenuShowItem.refreshItemPrice(this.state.item, PromotionHelper.getForItem(this.props.promotions, this.state.item.item_id), this.props.cart);
        this.setState({
            item: this.state.item
        });
    }
    getItemView(items, modiferGroups, availabilities, id, parentItem, parentModifierGroup, level, pick_date){
        let item = ItemHelper.getItem(items, id);
        if(item === null){
            return null;
        }
        let itemView = {};
        itemView.id = item.id;
        itemView.name = item.translation.name;
        itemView.description = item.translation.description;
        itemView.content = item.translation.content;
        itemView.image_link = item.image_link;
        itemView.disabled = !ItemHelper.isAvailabile(item, availabilities, DateService.emptyOrNow(pick_date));
        itemView.modifier_groups = [];
        itemView.min_permitted = 0;
        itemView.availability_id = item.availability_id;
        itemView.max_permitted = null;
        itemView.charge_above = null;
        itemView.price = ItemHelper.getPriceOvveride(item, parentItem, parentModifierGroup);
        if(parentItem === undefined || parentItem === null){
            parentItem = item;
        }
        itemView.quantity = 0;
        let itemQuantityInfo = ItemHelper.getItemQuantityInfo(item, parentItem, parentModifierGroup);
        if(itemQuantityInfo !== null){
            itemView.min_permitted = itemQuantityInfo.min_permitted;
            itemView.max_permitted = itemQuantityInfo.max_permitted;
            itemView.charge_above = itemQuantityInfo.charge_above;
            itemView.quantity = itemQuantityInfo.default_quantity !== undefined ? itemQuantityInfo.default_quantity : 0;
        }

        itemView.errors = [];
        itemView.isValid = true;
        if(level > 1){
            return itemView;
        }
        if(item.modifier_groups !== undefined){
            item.modifier_groups.forEach(itemModifierGroup => {
                let modifierGroup = ItemHelper.getModifierGroup(modiferGroups, itemModifierGroup.modifier_group_id);
                if(modifierGroup === null || !ModifierGroupHelper.isAvailabile(modifierGroup)){
                    return;
                }
                let modifierGroupView = {
                    id: modifierGroup.id,
                    name: modifierGroup.translation.name,
                    options: [],
                    min_permitted: 0,
                    max_permitted: null,
                    charge_above: null,
                    position: itemModifierGroup.position,
                    quantity: 0,
                    errors:[],
                    isValid: true
                }
                let quantity_info= ItemHelper.getModifierGroupQuantityInfo(modifierGroup, item);
                if(quantity_info !== null){
                    if(quantity_info.max_permitted !== null && quantity_info.max_permitted !== undefined){
                        modifierGroupView.max_permitted = quantity_info.max_permitted;
                    }
                    if(quantity_info.min_permitted !== null && quantity_info.min_permitted !== undefined){
                        modifierGroupView.min_permitted = quantity_info.min_permitted;
                    }
                    if(quantity_info.charge_above !== null && quantity_info.charge_above !== undefined){
                        modifierGroupView.charge_above = quantity_info.charge_above;
                    }
                }
                let stateModifierGroup = this.getStateModifierGroup(modifierGroup.id);
                modifierGroup.options.forEach(modifierGroupItem => {
                    let newLevel = level + 1;
                    let modifierGroupItemView = this.getItemView(items, modiferGroups, availabilities, modifierGroupItem.entity_id, parentItem, modifierGroup, newLevel, pick_date);
                    if(modifierGroupItemView === null){
                        return;
                    }
                    modifierGroupItemView.position = modifierGroupItem.position;
                    if(modifierGroupItemView !== null){
                        modifierGroupView.options.push(modifierGroupItemView);
                    }
                    let stateSelectedItem = this.getStateModifierGroupSelectedItem(stateModifierGroup, modifierGroupItem.entity_id);
                    modifierGroupItemView.quantity_free = stateSelectedItem ? stateSelectedItem.quantity_free : 0;
                    modifierGroupItemView.quantity = stateSelectedItem ? stateSelectedItem.quantity : 0;
                    modifierGroupView.quantity = modifierGroupView.quantity + modifierGroupItemView.quantity;
                });
                itemView.modifier_groups.push(modifierGroupView);
            });
        }
        return itemView;
    }
    validateState(itemView, availabilities, pick_date){
        const { t } = this.props;
        let isValid = true;

        itemView.modifier_groups.forEach(itemModifierGroup => {
            let quantity = itemModifierGroup.quantity;
            if(quantity < itemModifierGroup.min_permitted){

                if(itemModifierGroup.max_permitted !== null && itemModifierGroup.max_permitted === 1 && itemModifierGroup.min_permitted === 1){
                    itemModifierGroup.errors.push({"message": t("errors.internal.options_not_empty")});
                }else{
                    itemModifierGroup.errors.push({"message": t("errors.internal.options_min_permitted", {min_permitted: itemModifierGroup.min_permitted})});
                }
                itemModifierGroup.isValid = false;
                isValid = false;
            }
            if(itemModifierGroup.max_permitted !== null && quantity > itemModifierGroup.max_permitted){
                itemModifierGroup.errors.push({"message": t("errors.internal.options_max_permitted", {max_permitted: itemModifierGroup.max_permitted})});
                itemModifierGroup.isValid = false;
                isValid = false;
            }
            itemModifierGroup.options.forEach(itemModifierGroupItem => {
                let quantityItem = itemModifierGroupItem.quantity;

                if(quantityItem > 0 && itemModifierGroupItem.disabled){
                    itemModifierGroupItem.errors.push({"message": t("errors.internal.item_not_available")});
                    itemModifierGroupItem.isValid = false;
                    isValid = false;
                }

                // if(quantityItem > 0 && !AvailableHelper.isAvailabile(AvailableHelper.getAvailability(availabilities, itemModifierGroupItem.availability_id), DateService.emptyOrNow(pick_date))){
                //     itemModifierGroupItem.errors.push({"message": t("errors.internal.item_not_available")});
                //     itemModifierGroupItem.isValid = false;
                //     isValid = false;
                // }
                if(quantityItem < itemModifierGroupItem.min_permitted){

                    itemModifierGroupItem.errors.push({"message": t("errors.internal.options_item_min_permitted", {min_permitted: itemModifierGroupItem.min_permitted})});
                    itemModifierGroupItem.isValid = false;
                    isValid = false;
                }
                if(itemModifierGroupItem.max_permitted !== null && quantityItem > itemModifierGroupItem.max_permitted){
                    itemModifierGroupItem.errors.push({"message": t("errors.internal.options_item_max_permitted", {max_permitted: itemModifierGroupItem.max_permitted})});
                    itemModifierGroupItem.isValid = false;
                    isValid = false;
                }
            });
        });
        return isValid;
    }
    nl2br(text){
        return text.split('\n').map((item, key) => {
            return <React.Fragment key={key}>{item}<br/></React.Fragment>
        });
    }
    renderPromotions(){
        // let item = ItemHelper.getItem(this.props.items, this.state.item.id);
        let promotions = PromotionHelper.getForItem(this.props.promotions, this.state.item.item_id);
        if(promotions === undefined || promotions.length <=0) return null;
        return (
            <div className={"promotions"}>
                {promotions.map(promotion => {
                    return (
                        <div className={"btn btn-promotion"}>
                            <div className={"star"}>
                                <StarIco />
                            </div>
                            <div className={"content"}>
                                {promotion.name}
                                <small>
                                    Min. wartość koszyka: <Money value={promotion.min_basket_money} />
                                </small>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }
    render(){
        if(this.props.menuPending){
            return (
                <LoadingContainer/>
            )
        }
        const {items, modifier_groups, menus, pending, cart, availabilities, pick_date, t, preview, promotions} = this.props;
        // let id = parseInt(match.params.id);
        // let menuId = parseInt(match.params.menuId);
        let id = parseInt(this.props.id);
        let menuId = parseInt(this.props.menuId);
        let itemView = this.getItemView(items, modifier_groups, availabilities, id, null, null, 1, pick_date);
        if(itemView=== null){
            return (
                <LoadingContainer/>
            )
        }
        let isValid = this.validateState(itemView, availabilities, pick_date);


        let isMenuAvailabile = true;
        let errorView = null;
        let menusById = menus.filter(menu => menu.id === menuId);
        if(menusById.length > 0){
            let menu = menusById[0];
            let availability = AvailableHelper.getAvailability(availabilities, menu.availability?.id)
            if(!AvailableHelper.isAvailabile(availability, DateService.emptyOrNow(pick_date))){

                if(!AvailableHelper.isAvailabileDate(availability, DateService.emptyOrNow(pick_date))){
                    let pickDateText = "";
                    if(menu.pick_date){
                        pickDateText = pick_date ?  t("components.menu.unavailable_pickup_date") : t("components.menu.unavailable_today_pickup_date");
                    }else{
                        pickDateText = pick_date ?  t("components.menu.unavailable_pickup_date") : t("components.menu.unavailable_today");
                    }
                    errorView = (
                        <div className="alert alert-danger">
                            {pickDateText}
                        </div>
                    )
                }else{
                    // if(!ItemHelper.isMenuAvailabile(menusById[0], DateService.emptyOrNow(pick_date))){
                    isMenuAvailabile = false;
                    let todayOpenHours = ItemHelper.getTodayOpenHoursString(menusById[0].availability, DateService.emptyOrNow(pick_date));
                    let todayOpenHoursView = null;
                    if(todayOpenHours !== null){
                        todayOpenHoursView = t("components.menu.available_time") + ": " + todayOpenHours
                    }else{
                        todayOpenHoursView = t("components.menu.unavailable_today");
                    }
                    errorView = (
                        <div className="alert alert-danger">
                            {todayOpenHoursView}
                        </div>
                    )
                }
            }
        }

        let disabled = pending || !isValid || !isMenuAvailabile || itemView.disabled || cart.type === null;
        let loading = pending;
        let productImgView = null;
        if(itemView.image_link){
            productImgView = (
                <div className="product-item-img">
                    <img src={itemView.image_link.default} />
                </div>
            )
        }
        let disabledView = null;
        if(itemView.disabled){
            let errorMEssage = t("components.item.unavailable");
            disabledView = (
              <ErrorFormAlertView errors={errorMEssage} />
            );
        }else if(cart.type === null){
            if(!preview){
                let errorMEssage = [t("errors.internal.order_type_required")];
                disabledView = (
                    <ErrorFormAlertView errors={errorMEssage} />
                );
            }
        }
        // let product = this.props.product;
        return(
          <React.Fragment>
              <div className="product-item">
                  {disabledView}
                  {this.renderPromotions()}

                  {itemView.content
                      ? <p className="product-item-description"  dangerouslySetInnerHTML={{__html: Utils.nl2brString(itemView.content)}}></p>
                      : itemView.description
                          // ? <p className="product-item-description">
                          //     {Utils.nl2br(itemView.description)}
                          // </p>
                          ? <p className="product-item-description" dangerouslySetInnerHTML={{__html: Utils.nl2brString(itemView.description)}}></p>
                          : null
                  }
                  {!preview && itemView.modifier_groups.length > 0 ? (
                      <div className="product-item-modifiers">
                          {
                              itemView.modifier_groups.sort((a, b) => a.position - b.position || a.name.localeCompare(b.name)).map((modifierGroup) =>
                                  <MenuModifierGroups subQuantityItem={this.subQuantityItem} setQuantityItem={this.setQuantityItem} addQuantityItem={this.addQuantityItem} item={this.state.item} modifierGroup={modifierGroup} key={modifierGroup.id}></MenuModifierGroups>
                              )
                          }
                      </div>
                  ) : null}

                  {errorView}

                  {!preview && (
                      <div className="product-item-footer">
                          <ErrorFormAlertView errors={this.state.errors}/>
                          <div className="btn-group btn-group-submit">
                              <div className="input-group input-group-quantity">
                                  <div className="input-group-prepend">
                                      <button type="button" className="btn btn-default" onClick={this.subQuantity}>-</button>
                                  </div>
                                  <span className="input-group-text">{this.state.item.quantity}</span>
                                  <div className="input-group-append">
                                      <button type="button" className="btn btn-default" onClick={this.addQuantity}>+</button>
                                  </div>
                              </div>


                              <button className={"btn-spinner w-100 btn-primary btn btn-submit btn-submit-fixed-bottom" + (loading ? ' loading' : '')} variant="primary" disabled={disabled} onClick={()=>{this.addItem()}}>
                                  <span className="left spinner-border spinner-border-sm"></span>
                                  {t("components.menu.add_item", {quantity: this.state.item.quantity})}
                                  <div className="right btn-cart-price">
                                      {this.state.item.discount_price !== undefined ? (
                                          <div className={"prices"}>
                                             <span className="price price-discount">
                                                 <Money
                                                     value={this.state.item.total_price}
                                                     currency='PLN' />
                                             </span>
                                                      <span className="price">
                                                 <Money
                                                     value={this.state.item.discount_price}
                                                     currency='PLN' />
                                             </span>
                                          </div>
                                      ) : (

                                          <span className="price">
                                                <Money
                                                    value={this.state.item.total_price}
                                                    currency='PLN' />
                                            </span>
                                      )}
                                  </div>
                              </button>
                          </div>
                      </div>
                  )}
              </div>
          </React.Fragment>
        )
    }


}
const mapStateToProps = (state)=>{
    return {
        items: getItems(state.menu),
        menus: getMenus(state.menu),
        modifier_groups: getModifierGroups(state.menu),
        pending: getOrderPending(state.cart),
        cart: getCart(state.cart),
        location: getLocation(state.location),
        menuPending: getMenuPending(state.menu),
        availabilities: getAvailabilities(state.menu),
        isStoreOpen: isStoreOpen(state.menu),
        pick_date: getPickDate(state.cart),
        locale: getLocale(state.store),
        preview: isPreview(state.store),
        promotions: getPromotions(state.menu)
    }
}

export default withTranslation()(connect(mapStateToProps)(MenuShowItem))