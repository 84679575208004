import React, { Component } from 'react';
import Cookies from "universal-cookie/lib";
import {withTranslation} from "react-i18next";
import AppConfig from "../../application/common/AppConfig";

class CookiesAlert extends Component {
    constructor(props) {
        super(props);
        let cookies = new Cookies();
        this.state = {
            cookiesAlertHide: cookies.get("cookie_alert_hide")
        };
        this.close = this.close.bind(this);
    }

    close () {
        let cookies = new Cookies();
        cookies.set("cookie_alert_hide", true);

        this.setState(prevState => ({
            ...prevState,
            cookiesAlertHide: true
        }));
        // this.setCookie("cookie_alert_hide", true);
    }

    render() {
        if(AppConfig.isWidget()){
           return null;
        }
        if(this.state.cookiesAlertHide){
            return null;
        }
        const {t} = this.props;
        return(
            <div className="cookies">
                {t("components.cookies.title")}.
                <button className="btn btn-sm btn-primary" onClick={this.close}>{t("common.word.ok")}</button>
            </div>
        );
    }
}

export default withTranslation()(CookiesAlert);