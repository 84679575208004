import React from 'react';
import {connect} from "react-redux";
import {
    getAvailabilities,
    getItems,
    getMenus,
    getModifierGroups,
    getPromotions,
    isItemMultiLevel,
    isStoreOpen
} from "../../../../../application/menu/reducer";
import {getCart, getOrderPending, getPickDate} from "../../../../../application/cart/reducer";
import LoadingContainer from "../../../../common/LoadingContainer";
import ItemHelper from "../../../../../application/common/ItemHelper";
import {getLocation} from "../../../../../application/location/reducer";
import ErrorFormAlertView from "../../../../common/ErrorFormAlertView";
import DateService from "../../../../../application/service/DateService";
import AvailableHelper from "../../../../../application/common/AvailableHelper";
import Utils from "../../../../utils/Utils";
import Money from "../../../../common/Money";
import {withTranslation} from "react-i18next";
import {getLocale, isPreview} from "../../../../../application/store/reducer";
import PromotionHelper from "../../../../../application/common/PromotionHelper";
import {ReactComponent as StarIco} from "../../../../../images/icons/star.svg";
import MenuEditItemViewHelper from "./../MenuShowItem/MenuEditItemViewHelper";
import MenuModifierGroupsPickV2 from "./MenuModifierGroupsPickV2";
import MenuModifierGroupsNewV2 from "./MenuModifierGroupsNewV2";


const MenuEditItemVariantV3 = (props) => {
    let errors = props.errors;
    let stateItem = props.stateItem;
    const {
        cart,
        items,
        modifier_groups,
        menus,
        availabilities,
        pick_date,
        preview,
        pending,
        t
    } = props;

    const addQuantity = () => {
        setQuantity(stateItem.quantity + 1);
    };
    const subQuantity = () => {
        setQuantity(stateItem.quantity - 1);
    };
    const addItemInternal = () => {
        let menuId = parseInt(props.menuId);
        let newItem = stateItem;
        props.addItem(menuId, newItem);
    }
    const scrollToSection = () => {
        const headerOffset = 80; // Adjust this value based on your desired offset
        const modals = document.querySelectorAll('.modal');
        if (modals.length > 0) {
            const lastModal = modals[modals.length - 1];
            const errorElement = lastModal.querySelector('.error');
            if(errorElement){
                const targetTop = errorElement.getBoundingClientRect().top + lastModal.scrollTop - headerOffset;
                lastModal.scrollTo({
                    top: targetTop,
                    behavior: 'smooth'
                });
            }
        }
    };
    const updateState = () => {
        props.onUpdate(stateItem);
    }

    const setQuantity = (quantity) => {
        let newQuantity = quantity;
        if (newQuantity <= 0) {
            newQuantity = 1;
        }
        stateItem.quantity = newQuantity;
        updateState();
    }

    const getItemView = (items, modiferGroups, availabilities, id, parentItem, parentModifierGroup, level, pick_date) => {
        return MenuEditItemViewHelper.getItemView(items, modiferGroups, availabilities, id, parentItem, parentModifierGroup, level, pick_date, stateItem, props.itemMultiLevel);
    }
    const validateState = (itemView, availabilities, pick_date, lvl) => {
        return MenuEditItemViewHelper.validateState(itemView, availabilities, pick_date, lvl, t);
    }
    const renderPromotions = () => {
        // let item = ItemHelper.getItem(this.props.items, this.state.item.id);
        let promotions = PromotionHelper.getForItem(props.promotions, stateItem.item_id);
        if (promotions === undefined || promotions.length <= 0) return null;
        return (
            <div className={"promotions"}>
                {promotions.map(promotion => {
                    return (
                        <div className={"btn btn-promotion"}>
                            <div className={"star"}>
                                <StarIco/>
                            </div>
                            <div className={"content"}>
                                {promotion.name}
                                <small>
                                    Min. wartość koszyka: <Money value={promotion.min_basket_money}/>
                                </small>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    // let id = parseInt(match.params.id);
    // let menuId = parseInt(match.params.menuId);
    let id = parseInt(props.id);
    let menuId = parseInt(props.menuId);
    let itemView = getItemView(items, modifier_groups, availabilities, id, null, null, 1, pick_date);
    if (itemView === null) {
        return (
            <LoadingContainer/>
        )
    }

    let isValid = validateState(itemView, availabilities, pick_date);

    let isMenuAvailabile = true;
    let errorView = null;
    if (!props.parent) {
        let menusById = menus.filter(menu => menu.id === menuId);
        if (menusById.length > 0) {
            let menu = menusById[0];
            let availability = AvailableHelper.getAvailability(availabilities, menu.availability?.id)
            if (!AvailableHelper.isAvailabile(availability, DateService.emptyOrNow(pick_date))) {

                if (!AvailableHelper.isAvailabileDate(availability, DateService.emptyOrNow(pick_date))) {
                    let pickDateText = "";
                    if (menu.pick_date) {
                        pickDateText = pick_date ? t("components.menu.unavailable_pickup_date") : t("components.menu.unavailable_today_pickup_date");
                    } else {
                        pickDateText = pick_date ? t("components.menu.unavailable_pickup_date") : t("components.menu.unavailable_today");
                    }
                    errorView = (
                        <div className="alert alert-danger">
                            {pickDateText}
                        </div>
                    )
                } else {
                    // if(!ItemHelper.isMenuAvailabile(menusById[0], DateService.emptyOrNow(pick_date))){
                    isMenuAvailabile = false;
                    let todayOpenHours = ItemHelper.getTodayOpenHoursString(menusById[0].availability, DateService.emptyOrNow(pick_date));
                    let todayOpenHoursView = null;
                    if (todayOpenHours !== null) {
                        todayOpenHoursView = t("components.menu.available_time") + ": " + todayOpenHours
                    } else {
                        todayOpenHoursView = t("components.menu.unavailable_today");
                    }
                    errorView = (
                        <div className="alert alert-danger">
                            {todayOpenHoursView}
                        </div>
                    )
                }
            }
        }
    }

    let disabled = pending || !isValid || !isMenuAvailabile || itemView.disabled || cart.type === null;
    let loading = pending;

    let disabledView = null;
    if (itemView.disabled) {
        let errorMEssage = t("components.item.unavailable");
        disabledView = (
            <ErrorFormAlertView errors={errorMEssage}/>
        );
    } else if (cart.type === null) {
        if (!preview) {
            let errorMEssage = [t("errors.internal.order_type_required")];
            disabledView = (
                <ErrorFormAlertView errors={errorMEssage}/>
            );
        }
    }

    const editOption = () => {
        props.onChangePage();
    }
    const isPickModifierGroupView = (modifierGroup) => {
        if (props.parent) return false;
        let exist = modifierGroup.options.some(option => option.modifier_groups.length > 0);
        return exist;
    }
    let anyPickModifierGroup = itemView.modifier_groups.some(x => isPickModifierGroupView(x));
    const renderModifiers = () => {
        if (itemView.modifier_groups.length <= 0) return null;
        return (
            <div className="product-item-modifiers">
                {
                    itemView.modifier_groups.sort((a, b) => a.position - b.position || a.name.localeCompare(b.name)).map((modifierGroup) => {
                            if (anyPickModifierGroup) {
                                return (
                                    <MenuModifierGroupsPickV2 onChangePage={props.onChangePage} stateItem={stateItem}
                                                              onUpdate={props.onUpdate}
                                                              modifierGroup={modifierGroup}
                                                              itemView={itemView}
                                                              key={modifierGroup.id}></MenuModifierGroupsPickV2>
                                )
                            }
                            return (
                                <MenuModifierGroupsNewV2 onUpdate={updateState}
                                                         stateItem={stateItem}
                                                         modifierGroup={modifierGroup}
                                                         key={modifierGroup.id}></MenuModifierGroupsNewV2>
                            )
                        }
                    )
                }
            </div>
        )
    }

    if (props.parent && preview) {
        return null;
    }

    return (
        <>
            <div className="product-item">
                <div className={"product-item-content"}>
                    {itemView.content
                        ? <p className="product-item-description"
                             dangerouslySetInnerHTML={{__html: Utils.nl2brString(itemView.content)}}></p>
                        : itemView.description
                            ? <p className="product-item-description"
                                 dangerouslySetInnerHTML={{__html: Utils.nl2brString(itemView.description)}}></p>
                            : null
                    }
                    {errorView}
                    {props.children}
                    {disabledView}
                    {!props.parent && renderPromotions()}

                    {!preview && (
                        <>{renderModifiers()}</>
                    )}
                </div>

                {!preview && (
                    <>
                        <div className="product-item-footer row">
                            <ErrorFormAlertView errors={errors}/>
                            <div className="btn-group-submit">
                                {!props.parent && (
                                    <div className="input-group input-group-quantity">
                                        <div className="input-group-prepend">
                                            <button type="button" className="btn btn-default" onClick={subQuantity}>-
                                            </button>
                                        </div>
                                        <span className="input-group-text">{stateItem.quantity}</span>
                                        <div className="input-group-append">
                                            <button type="button" className="btn btn-default" onClick={addQuantity}>+
                                            </button>
                                        </div>
                                    </div>
                                )}


                                <button
                                    className={`btn-spinner w-100 btn-primary btn btn-submit btn-submit-fixed-bottom${loading ? " loading" : ""}${disabled ? " disabled-view" : ""}`}
                                    variant="primary" disabled={false} onClick={() => {
                                    if (disabled) {
                                        scrollToSection()
                                        return ;
                                    }
                                    addItemInternal()
                                }}>
                                    <span className="left spinner-border spinner-border-sm"></span>
                                    {!props.parent ? (
                                        <>{t("components.menu.add_item", {quantity: stateItem.quantity})}</>
                                    ) : (
                                        <>{t("common.action.save")}</>
                                    )}
                                    <div className="btn-cart-price">
                                        {stateItem.discount_price !== undefined ? (
                                            <div className={"prices"}>
                                         <span className="price price-discount">
                                             <Money
                                                 value={stateItem.total_price}
                                                 currency='PLN'/>
                                         </span>
                                                <span className="price">
                                             <Money
                                                 value={stateItem.discount_price}
                                                 currency='PLN'/>
                                         </span>
                                            </div>
                                        ) : (

                                            <span className="price">
                                            <Money
                                                value={stateItem.total_price}
                                                currency='PLN'/>
                                        </span>
                                        )}
                                    </div>
                                </button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )

}
const mapStateToProps = (state) => {
    return {
        items: getItems(state.menu),
        menus: getMenus(state.menu),
        modifier_groups: getModifierGroups(state.menu),
        pending: getOrderPending(state.cart),
        cart: getCart(state.cart),
        location: getLocation(state.location),
        availabilities: getAvailabilities(state.menu),
        isStoreOpen: isStoreOpen(state.menu),
        pick_date: getPickDate(state.cart),
        locale: getLocale(state.store),
        preview: isPreview(state.store),
        promotions: getPromotions(state.menu),
        itemMultiLevel: isItemMultiLevel(state.menu)
    }
}

export default withTranslation()(connect(mapStateToProps)(MenuEditItemVariantV3))