class Pixel {

    static facebookPixelId = null;
    static ReactPixel = null;

    static add(id){
        const ReactPixel =  require('react-facebook-pixel');
        this.ReactPixel = ReactPixel.default;
        const options = {
            autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
            debug: false, // enable logs
        };
        ReactPixel.default.init(id, undefined, options);
        this.shopFacebookPixelId = id;
    }
    static pageView(path){
        if(!this.ReactPixel) return;
        this.ReactPixel.pageView();
    }
    static purchase(order){
        if(!this.ReactPixel) return;
        var items = [];
        for(var i = 0; i < order.items.length;i++){
            var orderDataItem = order.items[i];
            var item = {
                id: orderDataItem.id,
                name: orderDataItem.name,
                sku: orderDataItem.item_id,
                price: orderDataItem.total_money.amount,
                currency: orderDataItem.total_money.currency,
                quantity: orderDataItem.quantity
            }
            items.push(item);
        }

        var purchaseData = {
            "transaction_id": order.id,
            // "affiliation": "Google online store",
            "value": order.total_money.amount,
            "currency": order.total_money.currency,
            "contents": items,
        };
        this.ReactPixel.track("Purchase", purchaseData);
    }

}
export default Pixel;