import React from 'react';
import {useTranslation} from "react-i18next";

const ErrorFormAlertView = (data)=>{
    const { t } = useTranslation();
    let errors = data.errors;
    let field = data.field;
    let fieldErrors = [];
    if(typeof errors === 'string'){
        fieldErrors.push({
            message: errors
        });
    }else{
        errors.map(error => {
            if (typeof error === 'string' || error instanceof String){
                fieldErrors.push({
                    message: error
                });
                return;
            }
            let errorField = error.field;
            let code = error.code;
            let message = error.message;

            if (errorField === field) {
                fieldErrors.push({
                    code: code,
                    message: message
                });
            }
        });
    }
    return(
        <React.Fragment>

           {
               fieldErrors.map((item) =>
                   <div className="alert alert-danger" key={item.code + "_" + item.message}>
                       {item.code
                           ?
                           t("errors." + item.code)
                           : item.message
                       }
                   </div>
               )
           }
        </React.Fragment>
    )
}

export default ErrorFormAlertView;
