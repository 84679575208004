import React, {Component} from 'react';
import {Route, Router, Switch} from 'react-router-dom'
import './App.scss';
import Cart from './components/routes/Cart'
import {cleanCart, fetchContact, fetchOrder, fixOrder, updateAddress} from "./application/cart/cartService";
import {connect} from "react-redux";
import fetchMenu from "./application/menu/fetchMenu";
import LoadingContainer from "./components/common/LoadingContainer";
import Summary from "./components/routes/Summary";
import FlashMessages from "./components/common/FlashMessages";
import {getLocale, getStore, getStoreError, getStorePending, isPreview} from "./application/store/reducer";
import ErrorView from "./components/common/ErrorView";
import BaseNavbar from "./components/routes/BaseNavbar";
import ApiService from "./application/common/apiService";
import StorageStore from "./application/common/storageStore";
import {updateInitData} from "./application/cart/actions";
import {UPDATE_LOCATION, updateLocation} from "./application/location/actions";
import {getLocation} from "./application/location/reducer";
import {Modal} from "react-bootstrap";
import {getCart, getOrderValid} from "./application/cart/reducer";
import {createBrowserHistory as createHistory} from 'history'
import AppConfig from "./application/common/AppConfig";
import IframeWidget from "./components/utils/IframeWidget"
import Analytics from "./application/common/Analytics";
import CustomStyle from "./components/layout/CustomStyle";
import {Helmet} from "react-helmet/es/Helmet";
import OrderContext from "./application/common/OrderContext";
import CookiesAlert from "./components/common/CookiesAlert";
import {I18nextProvider} from "react-i18next";
import i18n from "./lib/i18n";
import {changeLocale} from "./application/service/languageService";
import Utils from "./components/utils/Utils";
import UserService from "./application/user/userService";
import Pixel from "./application/common/Pixel";
import UserContext from "./application/common/UserContext";
import PasswordProtectedModal from "./components/common/PasswordProtectedModal";
import Cookies from "universal-cookie/lib";
// import i18n from "lib/i18n";
//
// import messages_pl from "./translations/pl.json";
// import messages_en from "./translations/en.json";
//
// let messages = {
//     pl: messages_pl
const cookies = new Cookies();
// }
class App extends Component{

    prefix = "";
    // goorderAnalyticsId = 'UA-157020511-1';
    // goorderAnalyticsId = 'UA-73259397-6';
    // goorderAnalyticsId = 'G-34NECSDQVS';
    goorderAnalyticsId = 'G-JYCDCN61ZX';

    cookies = new Cookies();

    history = null;
    baseName = "/";
    state = {
        modalLoading: false,
        messages: [],
        // errorWithOrder: false,
        helmetLoaded: false,
        userLoaded: true,
        password: undefined,
        passwordLogged: this.cookies.get("passwordLogged")
        // locale: null
    };
    defaultLocale = 'pl';
    orderWithPath = false;

    constructor(props) {
        super(props);
        let baseName = '/';
        let pathname = window.location.pathname;
        var body = document.body;
        body.classList.add("body");
        if(pathname.includes("widget_native")){
            this.prefix = "widget_native/";
            AppConfig.setNativeMobile();
            // AppConfig.setWidget(true);
            // IframeWidget.initialize(pathname);
        }else if(pathname.includes("widget")){
            this.prefix = "widget/";
            AppConfig.setWidget(true);
            IframeWidget.initialize(pathname);
        }
        if(pathname.includes("order")){
            OrderContext.initialize();
            this.prefix += "order/" + OrderContext.getOrderId() + "/" + OrderContext.getOrderToken() + "/";
            this.orderWithPath = true;
            // AppConfig.setWidget(true);
            // IframeWidget.initialize();
        }
        let storeDomain = '';
        if(pathname.includes("inner")){
            let pathnameSplit = pathname.split("/");
            if(pathnameSplit.length >= 2){
                storeDomain = pathnameSplit[2];
                baseName = "/inner/" + storeDomain + "/";
            }
        }
        this.initStorage(storeDomain, baseName + "");
        baseName = baseName + this.prefix;
        this.baseName = baseName;
        // this.props.basename = baseName;
        this.history = createHistory({basename: baseName});
        // this.history = createHistory(this.props);
        // this.history = useRouterHistory(createHistory)({
        //     basename: baseName
        // })
        this.newOrder = this.newOrder.bind(this);
        this.fixOrderAction = this.fixOrderAction.bind(this);
        let that = this;

        this.initI18n1();
        // this.initI18n(that.locale);
    }
    initI18n1(){
        let locale = "pl";
        i18n.init({
            // lng: "en",
            // debug: true,
            // lng: locale,
            // fallbackLng: locale,
            interpolation: {
                format: (value, rawFormat, lng) => {
                    const [format, ...additionalValues] = rawFormat.split(',').map((v) => v.trim());
                    switch (format) {
                        case 'uppercase':
                            return value.toUpperCase();
                        case 'money':
                            return Intl.NumberFormat(lng, {
                                style: 'currency',
                                currency: additionalValues[0]
                            }).format(value);
                    }
                }
            },
            backend: {
                loadPath: '/translations/{{lng}}/{{ns}}.json'
            },
            // react: {
            //     useSuspense: false
            // }
        })
    }

    initI18n(locale){
        this.props.dispatch(changeLocale(locale))
    }
    initStorage(storeDomain, baseName){
        ApiService.setStoreDomain(storeDomain);
        StorageStore.init(storeDomain, baseName);
        if(StorageStore.getItem("language")) this.defaultLocale = StorageStore.getItem("language");
        if(!this.orderWithPath){
            let contactJson = StorageStore.getItem('contact');
            let contact = null;
            if(contactJson !== null){
                if(typeof contactJson === 'object'){
                    contact = contactJson;
                }else{
                    contact = JSON.parse(contactJson);
                }
            }else{
                contact = {}
            }
            this.props.dispatch(updateInitData({
                contact:contact
            }));

            let query = window.location.search.substring(1);
            let qp = Utils.getQueryParams(query);
            if(qp.city !== undefined || qp.street !== undefined){

                let location = {
                    city: qp.city,
                    street: qp.street,
                    build_nr: qp.build_nr
                }
                // if(this.props.cart !== undefined){
                // this.props.dispatch(updateAddress(location, this.props.cart));
                // }else{
                this.props.dispatch({
                    type: UPDATE_LOCATION,
                    location: location
                });
                // }
            }else{
                let locationJson = StorageStore.getItem('location');
                let location = null;
                if(locationJson !== null){
                    location = JSON.parse(locationJson);
                    if(location.description === undefined){
                        location.description = '';
                    }
                    location.fullAddres = location.description;
                    this.props.dispatch({
                        type: UPDATE_LOCATION,
                        location: location
                    });
                }
            }
        }
    }
    async removeQueryParam(paramKey) {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.delete(paramKey);

        // Push the updated URL to the history
        this.history.replace({ search: searchParams.toString() });
    }
    async initUser() {
        let urlSearchParams = new URLSearchParams(this.history.location.search);
        let username = urlSearchParams.get("username");
        let password = urlSearchParams.get("password");
        let provider = urlSearchParams.get("provider");
        let userToken = urlSearchParams.get("user_token");
        if(userToken){
            UserContext.setToken(userToken);
            this.removeQueryParam("user_token");
            try {
                // let response = await props.dispatch(updateOrderChannelContact(provider, options, orderData))
                let response = await this.props.dispatch(UserService.refresh())
                this.setState({
                    "userLoaded": true
                });
            }catch (e) {
                console.log(e);
                throw e;
            }
            return;
        }
        if(provider !== undefined && provider && username !== undefined && username){
            try {
                // let response = await props.dispatch(updateOrderChannelContact(provider, options, orderData))
                let response = await this.props.dispatch(UserService.login(provider, username, password))
                this.setState({
                    "userLoaded": true
                });
            }catch (e) {
                console.log(e);
                throw e;
            }
        }else{
            try {
                // let response = await props.dispatch(updateOrderChannelContact(provider, options, orderData))
                let response = await this.props.dispatch(UserService.refresh())
                this.setState({
                    "userLoaded": true
                });
            }catch (e) {
                console.log(e);
                throw e;
            }
        }
        // if(userId && provider){
        //     this.state.userLoaded = false;
        //     this.setState({
        //         "userLoaded": false
        //     });
        //     this.fetchUser(provider, userId);
        // }
    }
    shouldUpdateAddress(oldAddress, newAddress){
        if(newAddress === undefined || !newAddress) return false;
        if(!newAddress.city && !newAddress.street && !newAddress.build_nr) return false;
        if(oldAddress === undefined || !oldAddress){
            return true;
        }
        if(newAddress.street !== oldAddress.street) return true;
        if(newAddress.city !== oldAddress.city) return true;
        if(newAddress.build_nr !== oldAddress.build_nr) return true;
        return false;
    }
    componentDidMount() {
        let that = this;
        Analytics.initGA(this.goorderAnalyticsId);
        let urlSearchParams = new URLSearchParams(this.history.location.search);
        let passwordProtection = urlSearchParams.get("password_protection");
        let selectedLanguage = urlSearchParams.get("lang");

        this.props.dispatch(fetchMenu(this.props.location.address)).then(
            response => {
                let translations = response.data.data.store.translations.map(translation => translation.locale);
                let locale = this.defaultLocale;
                if(translations && translations.length > 0){
                    if(selectedLanguage && translations.includes(selectedLanguage)){
                        locale = selectedLanguage;
                        that.removeQueryParam("lang");
                    }
                    if(!translations.includes(locale)){
                        locale = translations[0];
                    }
                }
                this.initI18n(locale);
                let settings = response.data.data.store.settings;
                AppConfig.setTemplate(settings.template);
                if(settings.password_protection){
                    let passwordLogged = this.state.passwordLogged;
                    if(passwordProtection && passwordProtection == settings.password_protection){
                        cookies.set("passwordLogged", true, {expires: new Date((new Date()).getTime() + 30 * 60 * 1000)});
                        this.removeQueryParam("password_protection");
                        passwordLogged = true;
                    }
                    this.setState({
                        "password": settings.password_protection,
                        "passwordLogged": passwordLogged
                    });
                }
                let googleAnalyticsId = response.data.data.store.google_analytics_id;
                if(googleAnalyticsId !== undefined && googleAnalyticsId){
                    Analytics.addGA(googleAnalyticsId);
                    Analytics.pageView(this.history.location.pathname);
                }
                let facebookPixelId = response.data.data.store.facebook_pixel_id;
                if(facebookPixelId !== undefined && facebookPixelId){
                    Pixel.add(facebookPixelId);
                    Pixel.pageView(this.history.location.pathname);
                }
            });
        this.props.dispatch(fetchOrder()).then(data => {
            let location = this.props.location !== undefined && this.props.location ? this.props.location.address : undefined;
            if(this.shouldUpdateAddress(data.address, location)){
                this.props.dispatch(updateAddress(location, this.props.cart));
            }else{
                if(data.address !== null) {
                    this.props.dispatch(updateLocation(data.address));
                }
            }
        }).catch(error => {

            // this.setState({
            //     errorWithOrder: true
            // })
        });
        this.initUser()
        // let urlSearchParams = new URLSearchParams(this.history.location.search);
        // let userId = urlSearchParams.get("user_id");
        // let provider = urlSearchParams.get("provider");
        // if(userId && provider){
        //     this.state.userLoaded = false;
        //     this.setState({
        //         "userLoaded": false
        //     });
        //     this.fetchUser(provider, userId);
        // }


        if(this.history !== undefined){
            Analytics.pageView(this.history.location.pathname);
            Pixel.pageView(this.history.location.pathname);
            this.history.listen((location) => {
                Analytics.pageView(location.pathname);
                Pixel.pageView(location.pathname);
                }
            );
        }
    }
    shouldComponentRender() {
        const {storePending} = this.props;
        return !storePending && this.state.userLoaded;

        // let isLangMessages = Object.keys(this.state.localeMessages).length !== 0;
        // return !storePending && isLangMessages;
        // if(menuPending === false && orderPending === false) return true;
        // return false;
    }

    renderHelment(store, noindex){
        if(store === undefined || !store || !store.name){
            return null;
        }
        let faviconView = null;
        if(store.ico_link){
            faviconView = (
                <link href={store.ico_link.small} rel="shortcut icon" type="image/png" />
            )
        }
        let urlCss = (store.settings && store.settings.css_url) ? store.settings.css_url : undefined;
        if(urlCss !== undefined && urlCss && !urlCss.includes(".css")) urlCss = undefined;

        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`${store.name}`} - Zamów jedzenie online</title>
                {noindex && (
                    <meta name="robots" content="noindex"/>
                )}
                {urlCss && (
                    <link rel="stylesheet" type="text/css" href={urlCss} />
                )}
                {faviconView}
            </Helmet>
        )
    }
    newOrder(){
        this.setState({
            modalLoading: true
        })
        cleanCart(this.props.dispatch);
        this.setState({
            modalLoading: false
        })
    }
    fixOrderAction(){
        this.setState({
            modalLoading: true
        })
        this.props.dispatch(fixOrder()).then(data => {
            this.setState({
                modalLoading: false
            })
        }).catch(error => {
            this.setState({
                modalLoading: false
            })
        });
    }
    onHideErrorModal(){

    }
    render(){
        const {store, locale, preview} = this.props;
        let routerView = null;
        let errorOrderModal = null;
        if(locale === undefined || !locale){
           return (
               <LoadingContainer/>
           )
        }

        if(this.shouldComponentRender()){

            if(this.props.storeError){
                if(this.props.storeError !== undefined && this.props.storeError.status === 404){
                    routerView = (
                        <ErrorView message="Sklep nie istnieje" />
                    )
                }else{
                    routerView = (
                        <ErrorView/>
                    )
                }
            }else{
                routerView = (
                    <React.Fragment>
                        <Switch>
                            <Route path={"/cart"} component={Cart} />
                            <Route path={"/summary/:id/:token"} component={Summary} />
                            <Route path={"/"} component={BaseNavbar}/>
                        </Switch>
                        <CookiesAlert/>
                    </React.Fragment>
                );
                if(store.id === undefined){
                    routerView = (
                        <LoadingContainer/>
                    );
                }
            }
            if(!this.props.orderValid) {
                errorOrderModal = (
                    <Modal dialogClassName="modal-full" show={true} onHide={this.onHideErrorModal}>
                        <div className="">
                            <Modal.Header>
                                <Modal.Title>
                                    Jest problem z Twoim zamówieniem.
                                </Modal.Title>
                            </Modal.Header>
                        </div>
                        <Modal.Body>
                            <p>
                                Twoje zamówienie zawiera produkty które są już niedostępne. Utwórz zamówienie lub
                                kliknij Popraw zamówienie w celu usunięcia nieaktualnych produktów
                            </p>
                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                className={"btn-spinner btn-primary btn" + (this.state.modalLoading ? ' loading' : '')}
                                disabled={this.state.modalLoading} onClick={this.fixOrderAction}>
                                <span className="left spinner-border spinner-border-sm"></span>
                                Popraw zamówienie
                            </button>
                            <button
                                className={"btn-spinner btn btn-danger" + (this.state.modalLoading ? ' loading' : '')}
                                disabled={this.state.modalLoading} onClick={this.newOrder}>
                                <span className="left spinner-border spinner-border-sm"></span>
                                Utwórz nowe zamówienie
                            </button>
                        </Modal.Footer>
                    </Modal>
                )
            }
        }else{
            routerView = (
                <LoadingContainer/>
            );
        }

        let lang = this.locale;
        // let appClassName = AppConfig.isWidget() ? " widget" : "";
        let appClassName = "";
        if(AppConfig.isWidget()){
            appClassName = " widget";
           // if(AppConfig.isNativeMobile()){
           //     appClassName = "widget-native-mobile";
           // }else{
           //     appClassName = "widget";
           // }
        }
        if(AppConfig.isNativeMobile()) {
            appClassName = " widget-native-mobile";
        }
        let appClassNamePreview = preview ? " preview" : "";
        let customCss = (
            <CustomStyle store={store} />
        )
        return (
            <React.Suspense fallback={(<LoadingContainer/>)}>
                <I18nextProvider i18n={i18n}>
                    {(this.state.password && !this.state.passwordLogged) ? (
                        <div className={"App" + appClassName + appClassNamePreview}>
                            {this.renderHelment(store, true)}
                            {customCss}
                            <PasswordProtectedModal password={this.state.password} onLogged={() => {
                                this.setState({
                                    "passwordLogged": true
                                });
                            }} />
                            <FlashMessages messages={this.state.messages} />
                        </div>
                    ) :(
                        <Router history={this.history} basename={this.baseName}>
                            <div className={"App" + appClassName + appClassNamePreview}>
                                {this.renderHelment(store)}
                                {customCss}
                                {routerView}
                                {errorOrderModal}
                                <FlashMessages messages={this.state.messages} />
                            </div>
                        </Router>
                    )}
                </I18nextProvider>
            </React.Suspense>
        );
    }
}

const mapStateToProps = state => ({
    storePending: getStorePending(state.store),
    storeError: getStoreError(state.store),
    store: getStore(state.store),
    location: getLocation(state.location),
    locale: getLocale(state.store),
    preview: isPreview(state.store),
    orderValid: getOrderValid(state.cart),
    cart: getCart(state.cart)
    // menuPending: getMenuPending(state.menu),
    // orderPending: getOrderPending(state.cart),
})

export default connect(mapStateToProps)(App)
