import Utils from "../../components/utils/Utils";

class AppConfig {

    static widget = false;
    static darkMode = false;
    static grid = false;
    static cart = true;
    static paid = true;
    static nativeMobile = false;
    static template = false;

    static setWidget(widget){
        this.widget = widget;

        if(window.location.search){
            let query = window.location.search.substring(1);
            let qp = Utils.getQueryParams(query);
            if(qp.darkMode){
                this.setDarkMode()
            }
            if(qp.only_items){
                this.cart = false;
            }
            if(qp.without_paid){
                this.paid = false;
            }
            if(qp.grid){
                this.grid = true;
            }
        }
    }
    static setDarkMode(){
        this.darkMode = true;
        var body = document.body;
        body.classList.add("dark-mode");
    }
    static setTemplate(template){
        this.template = template;
        var body = document.body;
        if(template === undefined){
            body.classList.add("design-old");
            return;
        }
        switch (template){
            case "LIGHT":
                body.classList.add("design-old");
                break;
            case "DARK":
                body.classList.add("design-old");
                AppConfig.setDarkMode();
                break;
            case "V2":
                body.classList.add("design-v2");
                break;
        }
    }
    static isDesignV2(){
        return this.template === "V2";
    }
    static isWidget(){
        return this.widget;
    }
    static setNativeMobile(){
        this.nativeMobile = true;
    }
    static isNativeMobile(){
        return this.nativeMobile;
    }
    static isDarkMode(){
        return this.darkMode;
    }
    static isGrid(){
        return this.grid;
    }
    static isCart(){
        return this.cart;
    }
    static isShowFreeInfo(){
        return false;
    }

}
export default AppConfig;