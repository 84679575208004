import React from 'react';
import {useTranslation} from "react-i18next";

const ErrorFormView = (data)=>{
    const { t } = useTranslation();

    let errors = data.errors;
    let field = data.field;
    let fieldErrors = [];
    errors.map(error => {
        if(error === undefined || !error){
            return;
        }
        let errorField = error.field;
        let code = error.code;
        let message = error.message;

        if (errorField === field) {
            fieldErrors.push({
                code: code,
                message: message
            });
        }
    });
    return(
        <React.Fragment>
           {
               fieldErrors.map((item) =>
                   <div className="invalid-feedback" key={item.code}>
                       {t("errors." + item.code)}
                   </div>
               )
           }
        </React.Fragment>
    )
}

export default ErrorFormView;
