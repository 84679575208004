import React, {useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getUser} from "../../application/user/reducer";
import UserService from "../../application/user/userService";
import LoginUserModal from "../common/LoginUserModal";
import {ReactComponent as UserIco} from "../../images/icons/user.svg";
import {Link} from "react-router-dom";
import {Dropdown} from "react-bootstrap";
import classNames from "classnames";
import NavbarMenus from "./NavbarMenus";
import NavbarUser from "./NavbarUser";
import Languages from "../common/Languages";
import {getLanguages, getStore} from "../../application/store/reducer";
import NavbarLanguages from "./NavbarLanguages";
import NavbarSearch from "./NavbarSearch";
import {getLocation} from "../../application/location/reducer";
import AppConfig from "../../application/common/AppConfig";
import NavbarMultiStore from "./NavbarMultiStore";

function Navbar(props){
    const { t, type, store, user, integrations } = props;
    const [modal, setModal] = useState(false);
    const [pagesCollapse, setPagesCollapse] = useState(false);

    let baseBackUrl = props.store.settings ? props.store.settings.back_url : undefined;
    let backUrlNative = props.store.settings ? props.store.settings.back_url_native : undefined ;

    let isMultiStore = props.store.settings.cfg_url ? true : false;
    let backUrl = AppConfig.isNativeMobile() && baseBackUrl ? backUrlNative : baseBackUrl;

    const renderLogoView = () => {
        if(store.image_link){
            return (
                <img alt="Logo location" src={store.image_link.default}/>
            )
        } else {
            return store.translation.name;
        }
    }

    let anyMenuLinks = store.links.some(x => x.type === "MENU");
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light sticky-fixed">
                <div className="navbar-brand-container">
                    <Link to="/" className="navbar-brand">
                        {renderLogoView()}
                    </Link>
                    <div className="navbar-options">
                        {(anyMenuLinks) && (
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" onClick={() => setPagesCollapse(!pagesCollapse)}>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        )}
                        <NavbarUser/>
                        <NavbarLanguages/>
                    </div>
                    {(backUrl || AppConfig.isNativeMobile()) ? (
                        <>
                            <div className="navbar-actions-container">
                                <NavbarMultiStore backUrl={backUrl} multiStore={isMultiStore} />
                            </div>
                            <div className={"navbar-actions-container-desktop"}>
                                <NavbarMultiStore backUrl={backUrl}  multiStore={isMultiStore} />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={"navbar-actions-container"}>
                                <NavbarSearch location={props.location}/>
                            </div>
                            <div className={"navbar-actions-container-desktop"}>
                                <NavbarSearch location={props.location} modal={true}/>
                            </div>
                        </>
                    )}
                    {(anyMenuLinks || (props.languages && props.languages.length > 1)) &&(
                        <div className={classNames(`navbar-menus collapse navbar-collapse`, {
                            'show': pagesCollapse
                        })} id="navbarNav">
                            {anyMenuLinks && (
                                <NavbarMenus links={store.links} />
                            )}
                        </div>
                    )}
                </div>
                {/*<div className={"navbar-actions"}>*/}
                {/*    <NavbarUser/>*/}
                {/*    <Languages/>*/}
                {/*</div>*/}
            </nav>
        </div>
    )
}

const mapStateToProps = (state)=>{
    return {
        user: getUser(state.user),
        store: getStore(state.store),
        location: getLocation(state.location),
        languages: getLanguages(state.store)
    }
}
export default withTranslation()(connect(mapStateToProps)(Navbar))
