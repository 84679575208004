import React, { Component } from 'react';
import Money from "../Money";
import {withTranslation} from "react-i18next";

class OrderSummaryData extends Component{


    renderDiscountMoney(){
        const { t } = this.props;
        if(this.props.order === undefined || this.props.order.discount_money === undefined || this.props.order.discount_money === null || this.props.order.discount_money.amount === 0){
            return null;
        }
        return (

            <div className="d-flex justify-content-between">
                <div>
                    {t("common.word.discount")}
                </div>
                <div>
                    <Money value={this.props.order.discount_money}/>
                </div>
            </div>
        )
    }
    render(){
        const { t } = this.props;
        let deliveryView = null;
        let order = this.props.order;
        let numberOfItems = order.items.length;
        if(this.props.order.type === 'DELIVERY' && this.props.order.delivery_fee_money){
            deliveryView = (
                <div className="d-flex justify-content-between">
                    <div>
                        {t("common.word.delivery")}
                    </div>
                    <div>
                        <Money value={order.delivery_fee_money} />
                    </div>
                </div>
            )
        }
        return(
            <div>
                {this.renderDiscountMoney()}
                <div className="d-flex justify-content-between">
                    <div>
                        {t("components.order.summary_items")}: {numberOfItems}
                    </div>
                    <div>
                        <Money value={order.sub_total_money} />
                    </div>
                </div>
                {deliveryView}
                <div className="d-flex justify-content-between">
                    <div>
                        <strong>
                            {t("common.word.sum")}
                        </strong>
                    </div>
                    <div>
                        <strong>
                            <Money value={order.total_money} />
                        </strong>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(OrderSummaryData)