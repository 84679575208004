import React from 'react';
import {Route, Switch} from "react-router-dom";
import Test from "./Test";
import Menu from "./base/Menu";
import Navbar from "../layout/Navbar";
import Rules from "./base/Rules";
import Page from "./base/Page";
import OrderType from "./base/OrderType";
import {withTranslation} from "react-i18next";

function BaseNavbar(props){
    return (
        <React.Fragment>
            <Navbar />
            <Switch>
                <Route path={`/test`} component={Test}/>
                <Route path={"/rules"} component={Rules} />
                <Route path={`/menu/:menuId`} component={Menu}/>
                <Route path={`/:orderType(DINE_IN|PICK_UP|DELIVERY|ROOM_SERVICE)`} component={OrderType}/>
                <Route path={`/:pageId`} component={Page}/>
                <Route path={`/`} component={Menu}/>
            </Switch>
        </React.Fragment>
    )
}
const mapStateToProps = (state)=>{
    return {
    }
}
export default withTranslation()(BaseNavbar)
