import React from 'react';
import logo from '../../images/logo/white.png';
import goposSmall from '../../images/gopos-small.png';
import goorderWhite from '../../images/logo/goorder_white.png';
import goorder from '../../images/logo/goorder.png';
import {getStore} from "../../application/store/reducer";
import {connect} from "react-redux";
import AppConfig from "../../application/common/AppConfig";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ReactComponent as FacebookIco} from "../../images/icons/social/facebook.svg";
import {ReactComponent as LinkedinIco} from "../../images/icons/social/linkedin.svg";
import {ReactComponent as InstagramIco} from "../../images/icons/social/instagram.svg";
import {ReactComponent as TwitterIco} from "../../images/icons/social/twitter.svg";

const Footer = (props)=>{

    const { t } = useTranslation();
    let renderStoreLinkView = function(storeLink){
        if(storeLink.link == 'rules'){
            return (
                <Link to={`/${storeLink.link}`}>
                    {storeLink.name}
                </Link>
            )
        }
        return (
            <a href={storeLink.link}>
                {storeLink.name}
            </a>
        )
    }
    let renderSocialIco = (name) => {
        switch (name) {
            case "facebook":
                return <FacebookIco className="icon" />
            case "instagram":
                return <InstagramIco className="icon" />
            case "linkedin":
                return <LinkedinIco className="icon" />
            case "twitter":
                return <TwitterIco className="icon" />
        }
    }
    let renderSocialLink = (name, value) => {
        switch (name) {
            case "facebook":
                return "https://facebook.com/" + value;
            case "instagram":
                return "https://instagram.com/" + value;
            case "linkedin":
                return "https://linkedin.com/company/" + value;
            case "twitter":
                return "https://twitter.com/" + value;
        }
    }
    let linksView = null;
    if(props.store && props.store.links && props.store.links.length > 0){
        linksView = (
            <ul className="footer-links">
                {
                    props.store.links.filter(x => x.type === "FOOTER").sort((a, b) => a.position - b.position || a.name.localeCompare(b.name)).map((storeLink) =>
                        <li className="" key={storeLink.id}>
                            {renderStoreLinkView(storeLink)}
                        </li>
                    )
                }
            </ul>
        )
    }
    let socialPortals = ["facebook", "instagram", "linkedin", "twitter"];
    let socialList = [];
    let socialViews = null;
    if(props.store && props.store.settings){
        for (const [key, value] of Object.entries(props.store.settings)) {
            if(socialPortals.includes(key) && value){
                socialList.push({"key": key, "value": value});
            }
        }
        if(socialList.length > 0){
            socialViews = (
                <ul className="footer-social">
                    {
                        socialList.map((socialItem) =>
                            <li className="" key={socialItem.key}>
                                <a target="_blank" href={renderSocialLink(socialItem.key, socialItem.value)}>
                                    {renderSocialIco(socialItem.key)}
                                </a>
                            </li>
                        )
                    }
                </ul>
            )
        }
    }
    if(AppConfig.isWidget()){
        return(
            <footer className="footer">
                <div className="container">

                    <div className="logo">
                        <a href="https://goorder.pl" target="_blank" title="GoOrder - Zamówienia online dla gastronomii">
                            <img alt="Logo location" src={goorder} />
                        </a>
                    </div>
                    {linksView}
                    {socialViews}
                    <div className="footer__use">
                        <img src={goposSmall} />
                        <a href="https://goorder.pl" target="_blank" title="GoOrder - Zamówienia online dla gastronomii">
                            {t("layout.footer.use_goorder")}
                        </a>
                    </div>
                </div>
            </footer>
        )
    }
    return(
        <footer className="footer">
            <div className="container">

                <div className="logo">
                    <a href="https://goorder.pl" target="_blank" title="GoOrder - Zamówienia online dla gastronomii">
                        <img alt="Logo location" src={goorderWhite} />
                    </a>
                </div>
                {linksView}
                {socialViews}

                <div className="footer__use">
                    <img src={goposSmall} />
                        <a href="https://goorder.pl" target="_blank" title="GoOrder - Zamówienia online dla gastronomii">
                            {t("layout.footer.use_goorder")}
                        </a>
                </div>
            </div>
        </footer>
    )
}

const mapStateToProps = state => ({
    store: getStore(state.store),
})
export default connect(mapStateToProps)(Footer)
