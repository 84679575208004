import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {getUser} from "../../application/user/reducer";
import UserService from "../../application/user/userService";
import LoginUserModal from "../common/LoginUserModal";
import {ReactComponent as UserIco} from "../../images/icons/user.svg";
import {Dropdown} from "react-bootstrap";
import {getLoginProviders, isPreview} from "../../application/store/reducer";

function NavbarUser(props){
    const { t, user, loginProviders } = props;
    const [modal, setModal] = useState(false);

    useEffect(() => {
        if(!props.preview){
            // login();         //@TODO: REMOVE!! MOCK!!
        }
    }, [])
    if(!loginProviders || loginProviders.length <=0) return null;
    if(props.preview) return null;
    const removeClient = () => {
        props.dispatch(UserService.logout()).then(() => {
        }).catch(error => {
            console.log(error);
            // props.updateErrors(errors);
            // this.updateErrors(error.errors);
            // this.setState({loading: false, errors:error.errors});
        });
    }
    const showModal = () => {
        setModal(true)
    }

    return (
        <>
            {(user && user.name) ? (
                <Dropdown className={"btn-user"}>
                    <Dropdown.Toggle className={""} variant="transparent" id="dropdown-basic">
                        {user.points !== undefined && (
                            <span className={"badge bg-success me-2"}>+{user.points} punktów</span>
                        )}
                        <UserIco/>
                    </Dropdown.Toggle>
                    <Dropdown.Menu alignRight>
                        <Dropdown.Item>
                            <span dangerouslySetInnerHTML={{__html: t("components.user.user_name", {"name": user.name})}} /><br />
                            {user.points !== undefined && (
                                <span className={"badge bg-success me-2"}>{t("components.user.your_points", {"points": user.points})}</span>
                            )}
                        </Dropdown.Item>
                        <Dropdown.Divider/>
                        <Dropdown.Item onClick={() => removeClient()}>
                            {t("components.user.logout")}
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            ) : (
                <>
                    <button className={"btn btn-transparent"} onClick={() => showModal()}>
                        <UserIco/>
                    </button>
                </>
            )}
            {modal && (
                <LoginUserModal onHide={() => setModal(false)} show={true} />
            )}
        </>
    )
}

const mapStateToProps = (state)=>{
    return {
        user: getUser(state.user),
        preview: isPreview(state.store),
        loginProviders: getLoginProviders(state.store)
    }
}
export default withTranslation()(connect(mapStateToProps)(NavbarUser))
