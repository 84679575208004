import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import ErrorFormHelper from "./ErrorFormHelper";
import ErrorFormView from "./ErrorFormView";
import ErrorFormAlertView from "./ErrorFormAlertView";
import {updateOrderData} from "../../application/cart/cartService";
import {withTranslation} from "react-i18next";
import {connect, useDispatch} from "react-redux";
import {getCart} from "../../application/cart/reducer";
import Languages from "./Languages";
import UserService from "../../application/user/userService";
import {getUser} from "../../application/user/reducer";

function LoginUserModal(props) {
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const t = props.t;
    const dispatch = useDispatch();

    const onShow = () => {
        setErrors([]);
    }
    const TranslationsInitialized = () => {
        t("errors.wrong_login");
        t("errors.username_notempty");
    }
    const addClient = async () => {
        setLoading(true);
        try {
            let response = await props.dispatch(UserService.login("GOCRM", email, password))
            setLoading(false);
            props.onHide()
        }catch (e) {
            console.log(e);
            if(e.status === 403 || e.status === 404){
                setErrors([
                    {
                        "code": "wrong_login",
                        "message": "Niepoprawne dane do zalogowania"
                    }
                ])
            }else if(e.status == 422){
                setErrors(e.errors);
            }else{
                setErrors([
                    {
                        "code": "wrong_login",
                        "message": "Nie udało się zalogować"
                    }
                ])
            }
            setLoading(false);
            // setErrors(e.errors)
            // console.log(e);
            // throw e;
        }
    }

    return(
        <React.Fragment>
            <Modal dialogClassName="modal-full" show={props.show} onHide={props.onHide} onShow={onShow}>
                <div className="">
                    <Modal.Header>
                        <Modal.Title>
                            {t("components.apps.loyalty.header.title")}
                        </Modal.Title>
                        <button onClick={props.onHide} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </Modal.Header>
                </div>
                <Modal.Body>
                    <div>
                        <ErrorFormAlertView errors={errors} />
                        <div className="form-group">
                            <input type="text" onChange={(e) => setEmail(e.target.value)} value={email} className={"form-control" + ErrorFormHelper(errors, "username")} placeholder={t("components.apps.loyalty.form.login")} aria-label="Recipient's username" aria-describedby="cart-username" />
                            <label htmlFor="cart-username">{t("components.apps.loyalty.form.login")}</label>
                            <ErrorFormView errors={errors} field="username" />
                        </div>
                        <div className="form-group">
                            <input type="password" onChange={(e) => setPassword(e.target.value)} value={password} className={"form-control" + ErrorFormHelper(errors, "password")} placeholder={t("components.apps.loyalty.form.password")} aria-label="Recipient's username" aria-describedby="cart-password" />
                            <label htmlFor="cart-password">{t("components.apps.loyalty.form.password")}</label>
                            <ErrorFormView errors={errors} field="password" />
                        </div>
                    </div>
                    <button className={"btn-spinner btn-block btn-primary btn btn-submit" + (loading ? ' loading' : '')} variant="primary" onClick={()=>{addClient()}}>
                        <span className="left spinner-border spinner-border-sm"></span>
                        {t("common.action.login")}
                    </button>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}

const mapStateToProps = (state)=>{
    return {
        user: getUser(state.user),
    }
}
export default withTranslation()(connect(mapStateToProps)(LoginUserModal))
