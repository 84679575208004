import React, { Component } from 'react';
import {connect} from "react-redux";
import {getStore, isPreview} from "../../../../application/store/reducer";
import {getHours, getLocationStore} from "../../../../application/menu/reducer";
import {getAddressName, getOpenHours} from "../../../../application/common/StringHelper";
import StoreInfoHours from "./StoreInfoHours";
import {getOrderType} from "../../../../application/cart/reducer";
import ChangeLocationModal from "../../../common/ChangeLocationModal";
import {getLocation} from "../../../../application/location/reducer";
import DateService from "../../../../application/service/DateService";
import {withTranslation} from "react-i18next";

class StoreInfo extends Component{

    state = {
        showAddressModal: false
    };

    constructor(props) {
        super(props);
        this.showAddressModal = this.showAddressModal.bind(this);
        this.hideAddressModal = this.hideAddressModal.bind(this);
    }
    renderOpenHours(hours, locationStore){
        const { t } = this.props;
        let openHours = getOpenHours(hours, DateService.now());
        let isOpenView = null;
        let isOpen = locationStore.isOpen;
        if(isOpen !== null){
            if(isOpen){
                isOpenView = (
                    <span className="badge bg-success">{t("components.store.status.open")}</span>
                );
            }else{
                isOpenView = (
                    <span className="badge bg-danger">{t("components.store.status.closed")}</span>
                );
                if(openHours == null || openHours.length <= 0){
                    return (
                        <div className="cover-time">
                            <span className="badge bg-danger">{t("components.store.status.closed_today")}</span>
                        </div>
                    );
                }
            }
        }

        return (
            <div className="cover-time">
                <ul className="list-inline">
                    {
                        openHours.map((openHour, index) =>
                            <li key={index + "-" + openHour.hour_from + "-"  + openHour.hour_to}>{openHour.hour_from} - {openHour.hour_to}</li>
                            // <span key={openHour.hour_from}>{openHour.hour_from} - {openHour.hour_to}</span>
                        )
                    }
                </ul>
                {isOpenView}
            </div>
        );
    }
    hideAddressModal(){
        this.setState({
            showAddressModal: false
        })
    }
    showAddressModal(){
        this.setState({
            showAddressModal: true
        })
    }
    render(){
        const {store, hours, locationStore, t, preview} = this.props;
        let tagsView = null;
        let addressView = null;
        if(store.tags.length > 0){
            tagsView = (
                <ul className="list-inline types">
                    {
                        store.tags.sort((a, b) => a.localeCompare(b)).map((tag) =>
                            <li key={tag}>{tag}</li>
                        )
                    }
                </ul>
            )
        }
        if(store.address){
            let addressFullString = getAddressName(store.address);
            addressView = (
                <div className="cover-address">
                    {addressFullString}
                </div>
            )
        }
        let openHours = this.renderOpenHours(hours, locationStore, locationStore);
        let estimateTimeView = null;

        let isAvailable = true;
        let isDeliveryAreaView = null;
        let shopInfoNotAvailableView = null;

        // if(!locationStore.isOpen){
        //     isAvailable = false;
        //     shopInfoNotAvailableView = (
        //         <div className="cover-content-center-info">
        //             {t("components.store.restaurant_closed")}
        //         </div>
        //     )
        // }else{
        //
        if(this.props.store.status !== "ACTIVE"){
            shopInfoNotAvailableView = (
                <div className="cover-content-center-info">
                    {t("errors.store_paused")}
                </div>
            )
            isAvailable = false;
        }else{
            if(!preview && this.props.orderType === "DELIVERY") {
                if (!locationStore.in_delivery_area) {
                    if(this.props.location.address.city === ''){
                        isDeliveryAreaView = (
                            <button className="badge bg-danger" onClick={this.showAddressModal}>{t("common.action.select_address")}</button>
                        );
                        shopInfoNotAvailableView = (
                            <div className="cover-content-center-info" onClick={this.showAddressModal}>
                                {t("errors.internal.store_address_not_exist")}. {" "}
                                <span>{t("common.action.change_address")}!</span>
                            </div>
                        )
                    }else{
                        isDeliveryAreaView = (
                            <button className="badge bg-danger" onClick={this.showAddressModal}>{t("errors.internal.store_address_beyond_delivery")}</button>
                        );
                        shopInfoNotAvailableView = (
                            <div className="cover-content-center-info" onClick={this.showAddressModal}>
                                {t("errors.internal.store_address_beyond_delivery")}. {" "}
                                <span>{t("common.action.change_address")}!</span>
                            </div>
                        )
                    }
                    isAvailable = false;
                }
            }
        }
        // }
        if(locationStore.estimated_delivery_duration || locationStore.estimated_preparation_duration){
            let estimateTime = locationStore.estimated_preparation_duration;
            if(this.props.orderType === "DELIVERY"){
                estimateTime = estimateTime + locationStore.estimated_delivery_duration;
            }
            estimateTimeView = (
                <label className="badge badge-default">
                    ~{estimateTime}min
                </label>
            )
        }
        let isAvailableView = null;
        if(!isAvailable){
            isAvailableView = (
                <div className="cover-content-center-bg-close"></div>
            )
        }
        let coverImgView = null;
        if(store.cover_link){
            coverImgView = (
                <img src={store.cover_link.default} />
            )
        }else{
            coverImgView = (
                <img src="/images/test/cover.jpg" />
            )
        }
        let headerView = null;
        if(store.translation.header){
            headerView = (
                <small>{store.translation.header}</small>
            )
        }
        return(
            <div className="cover">
                <div className="cover-img">
                    <div>
                        {coverImgView}
                    </div>
                </div>
                <div className="cover-content">
                    <div></div>
                    <div className="cover-content-center">
                        {isAvailableView}
                        {shopInfoNotAvailableView}
                        <div className="cover-content-center-content">
                            <div>
                                <h1>
                                    {store.translation.name}
                                    {headerView}
                                </h1>
                                {openHours}
                                {tagsView}
                                <div className="cover-tags">
                                    {estimateTimeView}
                                    {isDeliveryAreaView}
                                </div>
                                {addressView}
                                <StoreInfoHours/>
                            </div>
                        </div>
                    </div>
                    <div></div>
                </div>
                <ChangeLocationModal location={this.props.location} showModal={this.state.showAddressModal} onHide={this.hideAddressModal} />
            </div>
        )
    }
}
const mapStateToProps = (state)=>{
    return {
        store: getStore(state.store),
        hours: getHours(state.menu),
        // isStoreOpen: isStoreOpen(state.menu),
        orderType: getOrderType(state.cart),
        locationStore: getLocationStore(state.menu),
        location: getLocation(state.location),
        preview: isPreview(state.store)
    }
}

export default withTranslation()(connect(mapStateToProps)(StoreInfo))