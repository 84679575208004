import ItemHelper from "./ItemHelper";
import DateService from "../service/DateService";
import PromotionHelper from "./PromotionHelper";

class ItemViewHelper {

    static parseItemGroup(itemGroup, items, availabilities, promotions, pick_date, position, cart){
        let sortedItems = itemGroup.items.sort((a, b) => a.position - b.position);
        let firstItemId = sortedItems[0].item_id;
        let firstItem = items.filter(item => item.id == firstItemId)[0];
        let itemGroupsItems = itemGroup.items;
        if(!firstItem){
            sortedItems.forEach(sortedItem => {
                if(!firstItem){
                    firstItem = items.filter(item => item.id == sortedItem.item_id)[0];
                }
            })
        }
        // const hasDuplicatePosition = sortedItems.some((item, index, array) => array.findIndex(i => i.position === item.position) !== index);
        // if(hasDuplicatePosition){
        //     itemGroupsItems = itemGroup.items.filter(x => items.some(item => item.id == x.item_id));
        // }

        return {
            "id": itemGroup.reference_id,
            "reference_id": itemGroup.reference_id,
            "name": itemGroup.translation ? itemGroup.translation.name : itemGroup.name,
            "item": this.parseItem(firstItem, availabilities, promotions, pick_date, position, cart),
            "items": itemGroupsItems,
            "type": "ITEM_GROUP",
            "position": position
        }
    }
    static parseItem(item, availabilities, promotions, pick_date, position, cart){
        if(item == null){
            return;
        }
        let disabled = !ItemHelper.isAvailabile(item, availabilities, DateService.emptyOrNow(pick_date));
        let entityItem = {
            id: item.id,
            name: item.translation.name,
            position: position,
            description: item.translation.description,
            image_link: item.image_link,
            disabled: disabled,
            // price: item.price !== undefined ? item.price.amount : 0,
            // full_price: item.full_price,
            // price: item.full_price,
            price: (item.min_possible_price && item.min_possible_price > 0) ? item.min_possible_price : item.full_price,
            selected_items: item.selected_items
        };
        let discountPrice = this.getDiscountPrice(item.id, 1, item.full_price, promotions, cart, item.price.amount);
        if(discountPrice){
            entityItem.discount_price = {currency: item.price.currency, amount: discountPrice};
        }
        return entityItem;
    }
    static getDiscountPrice(itemId, quantity, unitPrice, promotions, cart, unitBasePrice){
        let itemPromotion = PromotionHelper.getForItemWithCart(promotions, itemId, cart).filter(promotion => promotion.type === "ITEM_FIXED_PRICE")[0];
        if(!itemPromotion) return undefined;
        let itemPromotionEntity = itemPromotion.entities.filter(x => x.entity_id == itemId)[0];
        if(!itemPromotionEntity) return undefined;
        if(!itemPromotionEntity.fixed_money) return undefined;
        let diffPrice = unitPrice - unitBasePrice;
        let newPrice = itemPromotionEntity.fixed_money.amount + diffPrice;
        return newPrice + (unitPrice * (quantity - 1));
    }
}


export default ItemViewHelper;